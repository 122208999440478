import { CSSProperties, useRef } from 'react';
import { Layout, Menu, Dropdown, Tag, Button, Space } from 'antd';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { MdOutlineNotificationAdd } from 'react-icons/md';
import { HiOutlinePuzzle, HiOutlineSpeakerphone } from 'react-icons/hi';
import { IoMdPaperPlane } from 'react-icons/io';
import { BiCodeAlt } from 'react-icons/bi';
import { RiDashboardLine } from 'react-icons/ri';

import LogoCoamo from '../../assets/LogoCoamo';
import Keycloak from '../../utils/Keycloak';

import MenuRouter from './MenuRouter';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const { Header, Sider } = Layout;

const styles: { [key: string]: CSSProperties } = {
    root: {
        width: '100%',
        height: '100%',
    },
    logoCoamo: {
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    header: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
    },
    headerActionSession: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 1,
        paddingRight: 30,
    },
    dropUser: {
        color: '#FFF',
    },
    tagAmbiente: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        marginBottom: 15,
    },
};

function TelaInicial() {
    const nomeRef = useRef(Keycloak.getToken()?.given_name ?? 'Usuário');
    const navigate = useNavigate();
    const location = useLocation();

    function getSelectedMenuFromUrl() {
        const url = String(location?.pathname)?.split('/')[1] ?? '';
        return `/${url}`;
    }

    return (
        <Layout style={styles.root}>
            <Header className="header" style={styles.header}>
                <div style={styles.logoCoamo} onClick={() => navigate('/')}>
                    <LogoCoamo size={130} />
                </div>

                <div style={styles.headerActionSession}>
                    <Dropdown
                        menu={{ items: opcoesUsuarioItems }}
                        trigger={['hover']}
                        placement="bottomCenter"
                    >
                        <Button type="text">
                            <Space>
                                <span style={styles.dropUser}>{nomeRef.current}</span>
                                <div className="center-content">
                                    <FiUser style={styles.dropUser} size={16} />
                                </div>
                            </Space>
                        </Button>
                    </Dropdown>
                </div>
            </Header>
            <Layout>
                <Sider width={200} className="site-layout-background">
                    {process.env.REACT_APP_NODE_ENV === 'stage' && (
                        <div style={styles.tagAmbiente}>
                            <Tag color="#108ee9">Ambiente de homologação</Tag>
                        </div>
                    )}

                    <Menu theme="dark" mode="inline" selectedKeys={[getSelectedMenuFromUrl()]}>
                        {(Keycloak.hasSuperAdmin() ||
                            Keycloak.hasAdminApp('') ||
                            Keycloak.hasUsuarioApp('')) && (
                            <Menu.Item key="/" icon={<RiDashboardLine size={20} />}>
                                <Link to="/">Dashboard</Link>
                            </Menu.Item>
                        )}

                        {(Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp('')) && (
                            <>
                                <Menu.Item key="/aplicativos" icon={<HiOutlinePuzzle size={20} />}>
                                    <Link to="/aplicativos">Aplicativos</Link>
                                </Menu.Item>

                                <Menu.Item
                                    key="/assuntos"
                                    icon={<HiOutlineSpeakerphone size={20} />}
                                >
                                    <Link to="/assuntos">Assuntos</Link>
                                </Menu.Item>
                            </>
                        )}

                        {(Keycloak.hasSuperAdmin() ||
                            Keycloak.hasAdminApp('') ||
                            Keycloak.hasUsuarioApp('')) && (
                            <>
                                <Menu.Item
                                    key="/enviar"
                                    icon={<MdOutlineNotificationAdd size={20} />}
                                >
                                    <Link to="/enviar">Nova notificação</Link>
                                </Menu.Item>

                                <Menu.Item key="/enviadas" icon={<IoMdPaperPlane size={20} />}>
                                    <Link to="/enviadas">Enviadas</Link>
                                </Menu.Item>
                            </>
                        )}

                        {(Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp('')) && (
                            <Menu.Item key="/templates" icon={<BiCodeAlt size={20} />}>
                                <Link to="/templates">Templates</Link>
                            </Menu.Item>
                        )}
                    </Menu>
                </Sider>
                <MenuRouter />
            </Layout>
        </Layout>
    );
}
const opcoesUsuarioItems = [
    {
        key: 'perfil',
        label: 'Perfil',
        icon: <FiUser size={16} />,
        onClick: () => window.open(Keycloak.getInstance().createAccountUrl(), '_blank'),
    },
    {
        key: 'sair',
        label: 'Sair',
        icon: <FiLogOut size={16} />,
        onClick: () => {
            window.localStorage.clear();
            Keycloak.getInstance().logout();
        },
    },
];

export default TelaInicial;
