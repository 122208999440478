import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import {
    Row,
    Input,
    Checkbox,
    Col,
    Table,
    Space,
    Divider,
    Button,
    Popconfirm,
    notification,
    Typography,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    UndoOutlined,
    CloudSyncOutlined,
} from '@ant-design/icons';
import Keycloak from '../../utils/Keycloak';
import { HiOutlinePuzzle } from 'react-icons/hi';

import { DEFAULT_PAGE_SIZE } from '../../api/Api';
import AplicativoSvc, { IObterAplicativos } from '../../services/AplicativoSvc';
import { Link } from 'react-router-dom';
import TagSituacao from '../../components/tag/TagSituacao';

const { Search } = Input;
const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
        fontSize: 28,
    },
    inputSearch: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    iconDelete: {
        color: 'red',
    },
    iconSyncTopic: {
        color: 'blue',
    },
};

function Aplicativos() {
    const timeSearch = useRef<any>(null);
    const [textoPesquisa, setTextoPesquisa] = useState('');
    const [excluidos, setExcluidos] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [aplicativos, setAplicativos] = useState<IObterAplicativos[]>([]);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: 'Chave única',
            dataIndex: 'chaveUnica',
            key: 'chaveUnica',
        },
        {
            title: 'Situação',
            key: 'situacao',
            align: 'center' as const,
            render: (_: string, item: IObterAplicativos) => (
                <TagSituacao ativo={!item.excluidoEm} />
            ),
        },
        {
            title: '',
            key: 'acao',
            align: 'center' as const,
            render: (_: string, item: IObterAplicativos) => (
                <Space size="middle">
                    <Link to={`/aplicativos/${item.id}`}>
                        <Button type="dashed" shape="circle" icon={<EditOutlined />} />
                    </Link>
                    <Popconfirm
                        title={`Deseja ${
                            !!item.excluidoEm ? 'ativar' : 'excluir'
                        } este aplicativo?`}
                        okText="Sim"
                        cancelText="Não"
                        icon={<QuestionCircleOutlined style={{ color: '#1052a8' }} />}
                        onConfirm={() =>
                            !!item.excluidoEm ? ativarAplicativo(item) : excluirAplicativo(item)
                        }
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={
                                !!item.excluidoEm ? (
                                    <UndoOutlined style={{ color: '#138504' }} />
                                ) : (
                                    <DeleteOutlined style={styles.iconDelete} />
                                )
                            }
                        />
                    </Popconfirm>
                    {!item?.excluidoEm && (Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp()) && (
                        <Popconfirm
                            title={`Deseja sincronizar os tópicos deste aplicativo com token's ativos de dispositivos deste aplicativo? Lembrando que esse processo é lento, pode demorar mais que o esperado, logo o backend continuará o processo mesmo depois do timeout da requisição, não envie a requisição varias vezes.`}
                            okText="Sim"
                            cancelText="Não"
                            icon={<QuestionCircleOutlined style={{ color: '#1052a8' }} />}
                            onConfirm={() => sincronizarTopicosComTokensAtivosDoAplicativo(item)}
                        >
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<CloudSyncOutlined style={styles.iconSyncTopic} />}
                            />
                        </Popconfirm>
                    )}
                </Space>
            ),
        },
    ];

    const carregar = useCallback(
        async (page: number, pageSize: number) => {
            try {
                setLoading(true);
                const response = await AplicativoSvc.obterAplicativos({
                    page,
                    limit: pageSize,
                    excluido: excluidos,
                    textoPesquisa,
                });

                setCurrentPage(response.page);
                setTotal(response.total);
                setAplicativos(response.result);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                notification.error({ message: 'Oops!', description: error.message });
            }
        },
        [excluidos, textoPesquisa]
    );

    async function ativarAplicativo(item: IObterAplicativos) {
        try {
            setLoading(true);
            await AplicativoSvc.ativarAplicativo(item.id);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    async function excluirAplicativo(item: IObterAplicativos) {
        try {
            setLoading(true);
            await AplicativoSvc.excluirAplicativo(item.id);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    async function sincronizarTopicosComTokensAtivosDoAplicativo(item: IObterAplicativos) {
        try {
            setLoading(true);
            await AplicativoSvc.sincronizarTopicosComTokensAtivosDoAplicativo(item.chaveUnica);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    useEffect(() => {
        carregar(1, DEFAULT_PAGE_SIZE);
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Row>
                    <Col span={12}>
                        <Row gutter={[16, 0]} align="middle">
                            <Col className="center-content no-padding" span={1}>
                                <HiOutlinePuzzle style={styles.iconPage} size={26} />
                            </Col>
                            <Col span={6}>
                                <Text style={styles.textTitle}>Aplicativos</Text>
                            </Col>
                        </Row>
                    </Col>
                    {Keycloak.hasSuperAdmin() && (
                        <Col span={12}>
                            <Row align="middle" justify="end">
                                <Link to={`/aplicativos/novo`}>
                                    <Button type="primary">Novo aplicativo</Button>
                                </Link>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Divider />
                <Row gutter={[32, 0]} align="middle">
                    <Col span={6}>
                        <Search
                            placeholder="Pesquisar aplicativo..."
                            onSearch={(val) => setTextoPesquisa(val)}
                            style={styles.inputSearch}
                            onChange={(e) => {
                                clearTimeout(timeSearch.current);
                                timeSearch.current = setTimeout(() => {
                                    setTextoPesquisa(e.target.value);
                                }, 600);
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <Checkbox
                            checked={excluidos}
                            onChange={(e) => setExcluidos(e.target.checked)}
                        >
                            Consultar excluídos
                        </Checkbox>
                    </Col>
                </Row>

                <Divider />
                <Row justify="start">
                    <Col span={12}>
                        <Table
                            style={styles.table}
                            loading={loading}
                            columns={columns}
                            dataSource={aplicativos}
                            rowKey={({ id }) => String(id)}
                            pagination={{
                                current: currentPage,
                                defaultCurrent: 1,
                                pageSize: DEFAULT_PAGE_SIZE,
                                total: total,
                                onChange: (page, pageSize) =>
                                    carregar(page, pageSize ?? DEFAULT_PAGE_SIZE),
                                showTotal: (registros: number, range: number[]) =>
                                    `${range[0]}-${range[1]} de ${total} registro(s)`,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Aplicativos;
