import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import Axios from './Axios';

export const DEFAULT_PAGE_SIZE = 6;

export interface IPaginationRequest {
    page: number;
    limit?: number;
}

export interface IPaginationResponse<ResultType> {
    result: ResultType[];
    limit: number;
    total: number;
    page: number;
}

async function load() {
    if (process.env.REACT_APP_MOCK === 'true') {
        const MockAdapter = require('axios-mock-adapter');
        const RouteMock = require('../mock');
        const mock = new MockAdapter.default(Axios, {
            delayResponse: 1000,
        });
        RouteMock.default(mock);
    }
}

async function request(params: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
        return await Axios(params);
    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            throw new Exception(
                'TIME OUT',
                'Parece que estamos com problemas, a operação está demorando mais que o esperado, tente novamente mais tarde',
                false,
                false
            );
        }
        if (String(error?.response?.data).includes('<html')) {
            throw new Exception(
                error.response.status,
                'Instabilidade temporária, aguarde alguns instantes e tente novamente mais tarde',
                false,
                false
            );
        }
        if (error.response) {
            if (error.response?.data?.mensagem) {
                throw new Exception(
                    error.response.status,
                    error.response.data.mensagem,
                    false,
                    false
                );
            }
            if (error.response?.data?.message) {
                throw new Exception(
                    error.response.status,
                    error.response.data.message,
                    false,
                    false
                );
            }
            if (error.response?.data?.error) {
                throw new Exception(error.response.status, error.response.data.error, false, false);
            }
            if (error.response?.status === 404) {
                throw new Exception(
                    error.response.status,
                    '404 - Serviço não encontrado',
                    false,
                    false
                );
            }
            throw new Exception(
                error.response ? error.response.status : 'NETWORK ERROR',
                error.response
                    ? typeof error.response.data !== 'object'
                        ? error.response.data
                        : JSON.stringify(error.response.data)
                    : 'Sem conexão com a internet',
                false,
                false
            );
        }

        if (error?.message === 'REQUESTCANCELED') {
            throw new Exception('REQUESTCANCELED', 'Requisição cancelada pelo client', false, true);
        }

        throw new Exception('NETWORK ERROR', 'Sem conexão com a internet', true, false);
    }
}

export class Exception extends Error {
    status: string;
    internetError: boolean;
    requestCanceled: boolean;

    constructor(status: string, message: string, internetError: boolean, requestCanceled: boolean) {
        super(message);

        this.status = status;
        this.internetError = internetError;
        this.requestCanceled = requestCanceled;
    }
}

export default {
    get: (url: string, params?: any, timeout?: number, cancelToken?: CancelToken, headers?: any) =>
        request({ method: 'get', url, params, timeout, cancelToken, headers }),
    post: (url: string, data?: any, timeout?: number, cancelToken?: CancelToken, headers?: any) =>
        request({ method: 'post', url, data, timeout, cancelToken, headers }),
    put: (url: string, data?: any, timeout?: number, cancelToken?: CancelToken, headers?: any) =>
        request({ method: 'put', url, data, timeout, cancelToken, headers }),
    delete: (url: string, data?: any, timeout?: number, cancelToken?: CancelToken, headers?: any) =>
        request({ method: 'delete', url, data, timeout, cancelToken, headers }),
    load: load,
};
