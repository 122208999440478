import { Tag } from 'antd';

function TagSituacao({ ativo, type }: { ativo: boolean; type?: 'default' | 'large' }) {
    return ativo ? (
        <Tag className={type === 'large' ? 'tag-large' : ''} color="green">
            Ativo
        </Tag>
    ) : (
        <Tag className={type === 'large' ? 'tag-large' : ''} color="red">
            Excluído
        </Tag>
    );
}

export default TagSituacao;
