import {
    ICriarAlterarAplicativoRequest,
    IObterAplicativoPorIdResponse,
    IObterAplicativos,
    IObterAplicativosRequest,
    IObterAplicativosResponse,
} from '../services/AplicativoSvc';

function AplicativoMock(mock: any) {
    mock.onGet('/v1/aplicativo').reply((req: any) => {
        const { excluido }: IObterAplicativosRequest = req.params ?? {};

        const result: IObterAplicativos[] = [];

        if (!excluido) {
            result.push({
                id: '75aca379-f43b-4685-934a-798590c9680f',
                nome: 'App Cooperado',
                chaveUnica: 'app-cooperado',
                excluidoEm: undefined,
            });
            result.push({
                id: '111111',
                nome: 'App Agrovet',
                chaveUnica: 'app-agrovet',
                excluidoEm: undefined,
            });
        } else {
            result.push({
                id: '222222',
                nome: 'App Teste',
                chaveUnica: 'app-teste',
                excluidoEm: new Date(2020, 10, 5, 15, 23, 56),
            });
        }

        const response: IObterAplicativosResponse = {
            result,
            limit: 20,
            page: 1,
            total: 2,
        };

        return [200, response];
    });

    mock.onDelete(new RegExp('/v1/aplicativo/*')).reply((req: any) => {
        const id = String(req.url).replace('/v1/aplicativo/', '');

        if (!id) {
            return [400, { mensagem: 'ID do aplicativo é obrigatório' }];
        }

        return [200, { mensagem: 'Aplicativo excluído' }];
    });

    mock.onPut(new RegExp('/v1/aplicativo/*/ativar')).reply((req: any) => {
        let id = String(req.url).replace('/v1/aplicativo/', '');
        id = String(id).replace('/ativar', '');

        if (!id) {
            return [400, { mensagem: 'ID do aplicativo é obrigatório' }];
        }

        return [200, { mensagem: 'Aplicativo ativado com sucesso!' }];
    });

    mock.onGet(new RegExp('/v1/aplicativo/*')).reply((req: any) => {
        const id = String(req.url).replace('/v1/aplicativo/', '');

        if (!id) {
            return [400, { mensagem: 'ID do aplicativo é obrigatório' }];
        }

        let response: IObterAplicativoPorIdResponse | null = null;

        if (id === '75aca379-f43b-4685-934a-798590c9680f') {
            response = {
                id,
                nome: 'App Cooperado',
                chaveUnica: 'app-cooperado',
                credencialFirebase:
                    '{"publicKey": "kla812890347n098nc10234", "privateKey": "v1n457078904q9nvqw407rt8q9ert70ew9r8we7r"}',
                excluidoEm: undefined,
            };
        } else if (id === '111111') {
            response = {
                id,
                nome: 'App Agrovet',
                chaveUnica: 'app-agrovet',
                credencialFirebase:
                    '{"publicKey": "kla812890347n098nc10234", "privateKey": "v1n457078904q9nvqw407rt8q9ert70ew9r8we7r"}',
                excluidoEm: undefined,
            };
        } else if (id === '222222') {
            response = {
                id,
                nome: 'App Teste',
                chaveUnica: 'app-teste',
                credencialFirebase:
                    '{"publicKey": "kla812890347n098nc10234", "privateKey": "v1n457078904q9nvqw407rt8q9ert70ew9r8we7r"}',
                excluidoEm: new Date(2020, 10, 5, 15, 23, 56),
            };
        } else {
            return [400, { mensagem: 'Aplicativo não encontrado' }];
        }

        return [200, response];
    });

    mock.onPost('/v1/aplicativo').reply((req: any) => {
        const { nome, chaveUnica }: ICriarAlterarAplicativoRequest = JSON.parse(req.data ?? '');

        if (!nome) {
            return [400, 'Nome do aplicativo é obrigatório'];
        }

        if (!chaveUnica) {
            return [400, 'Chave única do aplicativo é obrigatória'];
        }

        return [200, { mensagem: 'Aplicativo criado com sucesso!' }];
    });

    mock.onPut(new RegExp('/v1/aplicativo/*')).reply((req: any) => {
        const id = String(req.url).replace('/v1/aplicativo/', '');

        if (!id) {
            return [400, { mensagem: 'ID do aplicativo é obrigatório' }];
        }

        const { nome, chaveUnica }: ICriarAlterarAplicativoRequest = JSON.parse(req.data ?? '');

        if (!nome) {
            return [400, 'Nome do aplicativo é obrigatório'];
        }

        if (!chaveUnica) {
            return [400, 'Chave única do aplicativo é obrigatória'];
        }

        return [200, { mensagem: 'Aplicativo criado com sucesso!' }];
    });
}

export default AplicativoMock;
