import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import {
    Row,
    Input,
    Checkbox,
    Col,
    Table,
    Space,
    Divider,
    Button,
    Popconfirm,
    notification,
    Typography,
    Form,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import Keycloak from '../../utils/Keycloak';
import { BiCodeAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from '../../api/Api';
import TemplateSvc, { IObterTemplates } from '../../services/TemplateSvc';
import TagSituacao from '../../components/tag/TagSituacao';
import SearchAplicativo, {
    obterUltimoAplicativoSelecionado,
} from '../../components/input/SearchAplicativo';
import { IObterAplicativos } from '../../services/AplicativoSvc';

const { Search } = Input;
const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
        fontSize: 28,
    },
    inputSearch: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    iconDelete: {
        color: 'red',
    },
};

function Templates() {
    const timeSearch = useRef<any>(null);
    const [form] = Form.useForm();
    const [aplicativo, setAplicativo] = useState<IObterAplicativos | null>(
        obterUltimoAplicativoSelecionado()
    );
    const [textoPesquisa, setTextoPesquisa] = useState('');
    const [excluidos, setExcluidos] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [templates, setTemplates] = useState<IObterTemplates[]>([]);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
        },
        {
            title: 'Tipo',
            dataIndex: 'tecRender',
            key: 'tecRender',
        },
        {
            title: 'Situação',
            key: 'situacao',
            align: 'center' as const,
            render: (_: string, item: IObterTemplates) => <TagSituacao ativo={!item.excluidoEm} />,
        },
        {
            title: '',
            key: 'acao',
            align: 'center' as const,
            render: (_: string, item: IObterTemplates) => (
                <Space size="middle">
                    <Link to={`/templates/${item.id}`}>
                        <Button type="dashed" shape="circle" icon={<EditOutlined />} />
                    </Link>
                    <Popconfirm
                        title={`Deseja ${!!item.excluidoEm ? 'ativar' : 'excluir'} este template?`}
                        okText="Sim"
                        cancelText="Não"
                        icon={<QuestionCircleOutlined style={{ color: '#1052a8' }} />}
                        onConfirm={() =>
                            !!item.excluidoEm ? ativarTemplate(item) : excluirTemplate(item)
                        }
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={
                                !!item.excluidoEm ? (
                                    <UndoOutlined style={{ color: '#138504' }} />
                                ) : (
                                    <DeleteOutlined style={styles.iconDelete} />
                                )
                            }
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const carregar = useCallback(
        async (page: number, pageSize: number) => {
            if (!aplicativo?.id) {
                return;
            }

            try {
                setLoading(true);
                const response = await TemplateSvc.obterTemplates({
                    aplicativoId: aplicativo?.id,
                    page,
                    limit: pageSize,
                    excluido: excluidos,
                    textoPesquisa,
                });

                setCurrentPage(response.page);
                setTotal(response.total);
                setTemplates(response.result);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                notification.error({ message: 'Oops!', description: error.message });
            }
        },
        [excluidos, textoPesquisa, aplicativo]
    );

    async function ativarTemplate(item: IObterTemplates) {
        try {
            setLoading(true);
            await TemplateSvc.ativarTemplate(item.id);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    async function excluirTemplate(item: IObterTemplates) {
        try {
            setLoading(true);
            await TemplateSvc.excluirTemplate(item.id);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    useEffect(() => {
        carregar(1, DEFAULT_PAGE_SIZE);
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Row>
                    <Col span={12}>
                        <Row gutter={[16, 0]} align="middle">
                            <Col className="center-content no-padding" span={1}>
                                <BiCodeAlt style={styles.iconPage} size={25} />
                            </Col>
                            <Col>
                                <Text style={styles.textTitle}>Templates locais</Text>
                            </Col>
                        </Row>
                    </Col>
                    {Keycloak.hasSuperAdmin() && (
                        <Col span={12}>
                            <Row align="middle" justify="end">
                                <Link to={`/templates/novo`}>
                                    <Button type="primary">Novo template</Button>
                                </Link>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Divider />
                <Form form={form}>
                    <Row gutter={[32, 0]} align="middle">
                        <Col span={10}>
                            <Form.Item label="Aplicativo" labelCol={{ span: 24 }}>
                                <SearchAplicativo
                                    filtroExcluidos
                                    value={aplicativo}
                                    formItemName="aplicativo"
                                    form={form}
                                    onChange={(val) => setAplicativo(val)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[32, 0]} align="middle">
                    <Col span={6}>
                        <Search
                            placeholder="Pesquisar template..."
                            onSearch={(val) => setTextoPesquisa(val)}
                            style={styles.inputSearch}
                            onChange={(e) => {
                                clearTimeout(timeSearch.current);
                                timeSearch.current = setTimeout(() => {
                                    setTextoPesquisa(e.target.value);
                                }, 600);
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <Checkbox
                            checked={excluidos}
                            onChange={(e) => setExcluidos(e.target.checked)}
                        >
                            Consultar excluídos
                        </Checkbox>
                    </Col>
                </Row>

                <Divider />
                <Row justify="start">
                    <Col span={12}>
                        <Table
                            style={styles.table}
                            loading={loading}
                            columns={columns}
                            dataSource={templates}
                            rowKey={({ id }) => String(id)}
                            pagination={{
                                current: currentPage,
                                defaultCurrent: 1,
                                pageSize: DEFAULT_PAGE_SIZE,
                                total: total,
                                onChange: (page, pageSize) =>
                                    carregar(page, pageSize ?? DEFAULT_PAGE_SIZE),
                                showTotal: (registros: number, range: number[]) =>
                                    `${range[0]}-${range[1]} de ${total} registro(s)`,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Templates;
