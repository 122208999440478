import { FC } from 'react';

interface ILogoCoamoProps {
    size: number;
}

const LogoCoamo: FC<ILogoCoamoProps> = ({ size }) => {
    return (
        <svg width={size} viewBox="0 0 922 343" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M347.661 210.457C344.509 205.622 336.571 199.111 328.987 198.07C329.636 199.302 330.453 207.67 327.344 213.115C324.029 218.918 316.872 221.953 315.39 222.44C309.521 224.365 303.79 224.077 300.109 223.846C293.76 223.449 288.442 221.507 284.35 218.392C280.244 215.286 278.271 210.972 278.271 205.596C278.271 200.371 280.565 196.262 284.877 193.15C289.188 190.041 295.6 187.323 305.217 187.323C314.393 187.323 323.99 189.778 329.065 191.302C331.348 191.988 334.847 193.336 335.824 193.773C348.673 199.512 351.459 206.208 351.557 206.511C351.557 192.443 351.554 180.895 351.554 180.895C351.554 169.161 351.303 160.949 347.274 153.523C343.251 146.094 337.315 140.552 329.467 136.869C321.626 133.192 312.049 130.71 300.739 130.71C294.522 130.71 288.267 131.348 281.975 132.616C275.774 133.876 269.748 135.456 263.888 138.22C263.738 138.296 263.638 138.449 263.638 138.615V142.504C263.638 149.087 266.196 155.246 277.415 151.692C279.631 150.995 283.842 149.778 285.368 149.506C293.506 148.063 296.816 147.684 303.599 148.413C309.171 149.004 312.852 150.713 314.842 151.603C319.714 153.796 325.544 157.944 328.018 162.749C329.938 166.488 331.115 171.21 331.54 176.921C326.977 175.485 322.227 174.333 317.278 173.467C312.048 172.547 306.749 172.083 301.377 172.083C286.817 172.083 276.653 175.362 268.388 181.438C260.124 187.521 255.987 195.79 255.987 206.256C255.987 213.333 257.899 219.328 261.178 224.602C269.314 237.694 285.793 241.587 295.729 241.945C305.868 242.31 323.702 241.115 332.984 229.766C333.09 236.105 334.184 240.991 342.484 240.991H353.617C353.873 240.991 353.985 240.67 353.785 240.508C352.979 239.855 351.82 238.389 351.562 235.278C351.343 232.655 351.422 229.738 351.422 223.182C351.422 220.289 350.969 217.405 349.931 214.703C349.387 213.288 348.574 211.861 347.661 210.459V210.457Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M538.717 32.952C526.385 44.498 521.557 61.095 524.264 76.895C556.32 84.404 588.698 89.12 622.691 74.643C623.625 61.132 619.102 47.143 608.965 36.303C598.854 25.493 585.239 20 571.733 20C559.814 19.998 547.981 24.279 538.717 32.952Z"
                fill="url(#paint0_linear_601_2)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M369.855 89.8271C414.951 73.3911 454.715 85.3721 491.957 97.3501C544.162 114.141 591.411 130.933 641.441 69.4681C593.904 100.83 549.878 89.8981 506.055 78.9661C483.774 73.4101 461.541 67.8511 438.924 67.8491C416.426 67.8491 393.544 73.3501 369.856 89.8271H369.855Z"
                fill="url(#paint1_linear_601_2)"
            />
            <path
                d="M98.6304 241.272C86.117 241.272 75.3613 239.005 66.3738 234.472C57.3818 229.939 50.4074 223.538 45.4427 215.259C40.5064 207.028 38.0265 197.331 38 186.18V185.831C38.026 174.679 40.5064 164.987 45.4427 156.752C50.4069 148.478 57.3104 142.04 66.1569 137.431C75.0029 132.826 85.3938 130.523 97.3349 130.523C103.518 130.523 109.609 131.244 115.677 132.249C122.421 133.367 136.024 138.117 138.56 139.013C138.789 139.093 138.905 139.343 138.821 139.571L134.764 150.797C133.39 154.713 129.728 156.371 126.3 155.262C122.299 153.97 119.51 153.025 116.323 152.11C111.192 150.64 106.036 150.168 101.005 150.168C92.8045 150.168 85.8251 151.716 80.0737 154.81C74.3197 157.907 69.9672 162.117 67.0195 167.439C64.0693 172.767 62.595 178.883 62.595 185.792C62.595 192.557 64.0698 198.635 67.0195 204.032C69.9667 209.431 74.3567 213.712 80.1821 216.879C86.0075 220.047 93.0908 221.628 101.434 221.628C107.331 221.628 112.941 220.91 118.266 219.469C121.139 218.693 121.167 218.626 124.121 217.769C129.162 216.306 131.523 217.047 134.65 221.91L138.839 228.752C138.962 228.953 138.904 229.216 138.706 229.348L134.016 232.422C127.685 236.022 121.571 238.395 115.677 239.545C109.775 240.695 104.095 241.273 98.6294 241.273L98.6304 241.272Z"
                fill="white"
            />
            <path
                d="M194.315 222.058C201.793 222.058 208.087 220.513 213.194 217.416C218.3 214.322 222.149 210.043 224.739 204.572C227.329 199.104 228.624 192.913 228.624 186.004C228.624 178.811 227.329 172.513 224.739 167.114C222.15 161.717 218.3 157.473 213.194 154.379C208.087 151.284 201.793 149.735 194.315 149.735C186.978 149.735 180.756 151.285 175.65 154.379C170.543 157.473 166.696 161.717 164.108 167.114C161.519 172.513 160.224 178.811 160.224 186.004C160.224 192.913 161.519 199.104 164.108 204.572C166.696 210.043 170.543 214.322 175.65 217.416C180.756 220.513 186.978 222.058 194.315 222.058ZM194.315 241.271C181.51 241.271 170.76 238.897 162.058 234.147C153.353 229.399 146.77 222.85 142.314 214.502C137.855 206.157 135.626 196.659 135.626 186.004C135.626 175.356 137.855 165.856 142.314 157.508C146.77 149.163 153.353 142.579 162.058 137.755C170.759 132.934 181.51 130.522 194.315 130.522C207.116 130.522 217.905 132.933 226.68 137.755C235.453 142.579 242.07 149.163 246.531 157.508C250.988 165.856 253.22 175.356 253.22 186.004C253.22 196.659 250.988 206.157 246.531 214.502C242.07 222.85 235.453 229.399 226.68 234.147C217.905 238.898 207.116 241.271 194.315 241.271Z"
                fill="white"
            />
            <path
                d="M596.096 222.058C603.573 222.058 609.868 220.513 614.975 217.416C620.08 214.322 623.93 210.043 626.52 204.572C629.109 199.104 630.404 192.913 630.404 186.004C630.404 178.811 629.109 172.513 626.52 167.114C623.93 161.717 620.08 157.473 614.975 154.379C609.867 151.284 603.573 149.735 596.096 149.735C588.759 149.735 582.536 151.285 577.431 154.379C572.324 157.473 568.476 161.717 565.889 167.114C563.299 172.513 562.004 178.811 562.004 186.004C562.004 192.913 563.299 199.104 565.889 204.572C568.476 210.043 572.324 214.322 577.431 217.416C582.536 220.513 588.759 222.058 596.096 222.058ZM596.096 241.271C583.291 241.271 572.54 238.897 563.837 234.147C555.134 229.399 548.553 222.85 544.094 214.502C539.636 206.157 537.406 196.659 537.406 186.004C537.406 175.356 539.636 165.856 544.094 157.508C548.554 149.163 555.134 142.579 563.837 137.755C572.54 132.934 583.291 130.522 596.096 130.522C608.896 130.522 619.686 132.933 628.461 137.755C637.233 142.579 643.85 149.163 648.311 157.508C652.768 165.856 655 175.356 655 186.004C655 196.659 652.768 206.157 648.311 214.502C643.85 222.85 637.233 229.399 628.461 234.147C619.686 238.898 608.896 241.271 596.096 241.271Z"
                fill="white"
            />
            <path
                d="M530.588 178.705C530.588 177.435 530.588 166.34 524.772 155.248C518.958 143.977 507.508 130.523 482.614 130.523C463.714 130.523 452.262 138.34 445.541 146.884C438.816 138.34 427.365 130.523 408.465 130.523C383.569 130.523 372.119 143.977 366.305 155.248C360.489 166.34 360.489 177.435 360.489 178.705V230.811C360.489 236.428 365.041 240.979 370.652 240.979H382.66V229.888V194.813V178.705C382.66 177.254 382.843 175.796 383.025 174.344C383.569 171.616 384.297 168.525 386.113 165.07C390.478 156.888 397.743 152.704 408.466 152.704C419.188 152.704 426.821 156.705 430.821 164.705C434.274 171.434 434.456 178.158 434.456 178.705V230.784C434.456 236.42 439.023 240.991 444.657 240.991H456.627V229.832V193.397V178.706C456.627 177.254 456.809 175.796 457.171 174.345C457.718 171.617 458.44 168.525 460.079 165.07C464.256 156.888 471.709 152.704 482.614 152.704C493.335 152.704 500.605 156.706 504.783 164.706C508.055 171.252 508.6 178.341 508.6 178.706V194.813V230.796C508.6 236.368 513.116 240.887 518.685 240.887L530.588 240.889L530.579 229.882C530.579 229.863 530.588 229.849 530.588 229.833V178.706V178.705Z"
                fill="white"
            />
            <g clipPath="url(#clip0_601_2)">
                <path
                    opacity="0.2"
                    d="M851.114 130.517L751.439 170.559C750.59 170.892 749.864 171.48 749.361 172.241C748.857 173.002 748.601 173.899 748.626 174.811C748.651 175.724 748.957 176.606 749.502 177.338C750.046 178.07 750.803 178.616 751.67 178.902L801.286 196.146C802.257 196.474 803.08 197.137 803.608 198.015L831.036 242.813C831.5 243.599 832.195 244.222 833.027 244.598C833.858 244.974 834.785 245.084 835.682 244.915C836.578 244.745 837.4 244.303 838.037 243.648C838.673 242.994 839.092 242.16 839.237 241.259L857.101 135.339C857.24 134.565 857.167 133.768 856.892 133.032C856.617 132.295 856.148 131.647 855.536 131.154C854.924 130.661 854.19 130.341 853.412 130.229C852.634 130.117 851.84 130.217 851.114 130.517Z"
                    fill="white"
                />
                <path
                    d="M858.289 127.735C857.068 126.744 855.603 126.097 854.047 125.863C852.492 125.629 850.902 125.815 849.442 126.402L749.823 166.437C748.159 167.124 746.742 168.297 745.757 169.803C744.771 171.309 744.264 173.078 744.301 174.877C744.339 176.677 744.919 178.423 745.966 179.887C747.013 181.351 748.477 182.464 750.168 183.081L799.84 200.319L827.273 245.172C828.152 246.594 829.414 247.739 830.915 248.476C832.415 249.212 834.094 249.509 835.756 249.334L836.528 249.251C838.309 248.919 839.945 248.046 841.213 246.751C842.481 245.456 843.319 243.803 843.614 242.015L861.479 136.094C861.741 134.543 861.583 132.95 861.023 131.48C860.462 130.01 859.518 128.717 858.289 127.735ZM834.859 240.504L808.187 197.019L828.507 171.786C828.906 171.337 829.211 170.811 829.401 170.241C829.591 169.671 829.664 169.068 829.614 168.469C829.564 167.87 829.393 167.287 829.112 166.756C828.83 166.225 828.443 165.757 827.975 165.38C827.507 165.003 826.967 164.725 826.388 164.563C825.809 164.401 825.203 164.358 824.607 164.437C824.011 164.516 823.438 164.716 822.921 165.023C822.405 165.331 821.956 165.74 821.603 166.226L801.283 191.46L753.111 174.674L852.73 134.639L834.859 240.504Z"
                    fill="white"
                />
            </g>
            <path
                d="M796 204.5C796 204.5 773.121 225.398 755.5 235.5C740.775 243.942 734.388 248.527 718.5 254.5C700.64 261.214 669.5 266.5 669.5 266.5"
                stroke="white"
                strokeOpacity="0.8"
                strokeWidth="8"
                strokeDasharray="20 20"
            />
            <path
                d="M811 242C811 242 777.399 269.907 755.5 278.5C734.807 286.62 689.5 294.5 689.5 294.5"
                stroke="white"
                strokeOpacity="0.8"
                strokeWidth="5"
            />
            <path
                d="M758 192C758 192 733.399 212.907 711.5 221.5C690.807 229.62 654 237 654 237"
                stroke="white"
                strokeOpacity="0.8"
                strokeWidth="5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_601_2"
                    x1="573.04"
                    y1="20.007"
                    x2="574.085"
                    y2="88.914"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCE00" />
                    <stop offset="0.588235" stopColor="#FD8D00" />
                    <stop offset="1" stopColor="#FA4C00" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_601_2"
                    x1="369.855"
                    y1="83.9401"
                    x2="641.442"
                    y2="83.9401"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6DB900" />
                    <stop offset="0.14902" stopColor="#379C0F" />
                    <stop offset="0.301961" stopColor="#36AF03" />
                    <stop offset="0.788235" stopColor="#007E1F" />
                    <stop offset="1" stopColor="#007E1F" />
                </linearGradient>
                <clipPath id="clip0_601_2">
                    <rect
                        width="142"
                        height="142"
                        fill="white"
                        transform="translate(733 123.229) rotate(-6.15655)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoCoamo;
