import {
    Row,
    Col,
    Typography,
    Divider,
    Form,
    Input,
    Checkbox,
    Select,
    Table,
    notification,
    Tag,
    Space,
    Popconfirm,
    Button,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { DEFAULT_PAGE_SIZE } from '../../api/Api';
import SearchAplicativo, {
    obterUltimoAplicativoSelecionado,
} from '../../components/input/SearchAplicativo';
import TagSituacao from '../../components/tag/TagSituacao';
import AssuntoSvc, { IObterAssuntos } from '../../services/AssuntoSvc';
import { Link } from 'react-router-dom';
import { IObterAplicativos } from '../../services/AplicativoSvc';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const styles: { [key: string]: CSSProperties } = {
    iconPage: {
        color: 'black',
        fontSize: 28,
    },
    textTitle: {
        fontSize: 20,
    },
    inputSearch: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    iconDelete: {
        color: 'red',
    },
};

function Assuntos() {
    const timeSearch = useRef<any>(null);
    const [form] = Form.useForm();
    const [aplicativo, setAplicativo] = useState<IObterAplicativos | null>(
        obterUltimoAplicativoSelecionado()
    );
    const [tituloPesquisa, setTituloPesquisa] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [assuntos, setAssuntos] = useState<IObterAssuntos[]>([]);
    const [excluidos, setExcluidos] = useState(false);
    const [topicos, setTopicos] = useState('');
    const [opcionais, setOpcionais] = useState('');

    const columns = [
        {
            title: 'Título',
            dataIndex: 'titulo',
            key: 'titulo',
        },
        {
            title: 'Opcional',
            dataIndex: 'opcional',
            key: 'opcional',
            align: 'center' as const,
            render: (_: string, item: IObterAssuntos) => <TagEscolha opcao={item.opcional} />,
        },
        {
            title: 'Tópico',
            dataIndex: 'topico',
            key: 'topico',
            align: 'center' as const,
            render: (_: string, item: IObterAssuntos) => <TagEscolha opcao={item.topico} />,
        },
        {
            title: 'Chave única',
            dataIndex: 'chaveUnica',
            key: 'chaveUnica',
        },
        {
            title: 'Situação',
            key: 'situacao',
            align: 'center' as const,
            render: (_: string, item: IObterAssuntos) => <TagSituacao ativo={item.ativo} />,
        },
        {
            title: '',
            dataIndex: 'acao',
            align: 'center' as const,
            render: (_: string, item: IObterAssuntos) => (
                <Space size="middle">
                    <Link to={`/assuntos/${item.id}`}>
                        <Button type="dashed" shape="circle" icon={<EditOutlined />} />
                    </Link>
                    <Popconfirm
                        title={`Deseja ${!!item.excluidoEm ? 'ativar' : 'excluir'} este assunto?`}
                        okText="Sim"
                        cancelText="Não"
                        icon={<QuestionCircleOutlined style={{ color: '#1052a8' }} />}
                        onConfirm={() => {
                            !!item.excluidoEm ? ativarAssunto(item) : excluirAssunto(item);
                        }}
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={
                                !!item.excluidoEm ? (
                                    <UndoOutlined style={{ color: '#138504' }} />
                                ) : (
                                    <DeleteOutlined style={styles.iconDelete} />
                                )
                            }
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    function TagEscolha({ opcao, type }: { opcao: boolean; type?: 'default' | 'large' }) {
        return opcao ? (
            <Tag className={type === 'large' ? 'tag-large' : ''} color="blue">
                Sim
            </Tag>
        ) : (
            <Tag className={type === 'large' ? 'tag-large' : ''} color="default">
                Não
            </Tag>
        );
    }

    const carregar = useCallback(
        async (page: number, pageSize: number) => {
            try {
                setLoading(true);

                const response = await AssuntoSvc.obterAssuntos({
                    aplicativoId: aplicativo?.id ?? '',
                    titulo: tituloPesquisa,
                    page,
                    assuntoExcluido: excluidos,
                    limit: pageSize,
                    opcional: opcionais === '' ? undefined : opcionais === 'true' ? true : false,
                    topico: topicos === '' ? undefined : topicos === 'true' ? true : false,
                });

                setCurrentPage(response.page);
                setTotal(response.total);
                setAssuntos(response.result);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                notification.error({ message: 'Oops!', description: error.message });
            }
        },
        [aplicativo, tituloPesquisa, topicos, opcionais, excluidos]
    );

    async function ativarAssunto(item: IObterAssuntos) {
        try {
            setLoading(true);
            await AssuntoSvc.ativarAssunto(item.id);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    async function excluirAssunto(item: IObterAssuntos) {
        try {
            setLoading(true);
            await AssuntoSvc.excluirAssunto(item.id);
            carregar(currentPage, DEFAULT_PAGE_SIZE);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    useEffect(() => {
        carregar(1, DEFAULT_PAGE_SIZE);
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Row>
                    <Col span={12}>
                        <Row gutter={[16, 0]} align="middle">
                            <Col className="center-content no-padding" span={1}>
                                <HiOutlineSpeakerphone style={styles.iconPage} size={26} />
                            </Col>
                            <Col span={6}>
                                <Text style={styles.textTitle}>Assuntos</Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row align="middle" justify="end">
                            <Link to={`/assuntos/novo`}>
                                <Button type="primary">Novo assunto</Button>
                            </Link>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Form form={form}>
                    <Row gutter={[32, 0]} align="middle">
                        <Col span={10}>
                            <Form.Item label="Aplicativo" labelCol={{ span: 24 }}>
                                <SearchAplicativo
                                    filtroExcluidos
                                    value={aplicativo}
                                    formItemName="aplicativo"
                                    form={form}
                                    onChange={(val) => setAplicativo(val)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[16, 0]} align="middle">
                    <Col span={6}>
                        <Form.Item label="Título do assunto" labelCol={{ span: 24 }}>
                            <Search
                                placeholder="Pesquisar pelo título..."
                                onSearch={(val) => setTituloPesquisa(val)}
                                style={styles.inputSearch}
                                onChange={(e) => {
                                    clearTimeout(timeSearch.current);
                                    timeSearch.current = setTimeout(() => {
                                        setTituloPesquisa(e.target.value);
                                    }, 600);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Opcional" labelCol={{ span: 24 }}>
                            <Select value={opcionais} onChange={(val) => setOpcionais(val)}>
                                <Option value="">Todos</Option>
                                <Option value="true">Sim</Option>
                                <Option value="false">Não</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Tópico" labelCol={{ span: 24 }}>
                            <Select value={topicos} onChange={(val) => setTopicos(val)}>
                                <Option value="">Todos</Option>
                                <Option value="true">Sim</Option>
                                <Option value="false">Não</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Checkbox
                            checked={excluidos}
                            onChange={(e) => setExcluidos(e.target.checked)}
                        >
                            Consultar assuntos excluídos
                        </Checkbox>
                    </Col>
                </Row>
                <Divider />
                <Row justify="start">
                    <Col span={16}>
                        <Table
                            style={styles.table}
                            loading={loading}
                            columns={columns}
                            dataSource={assuntos}
                            rowKey={({ id }) => String(id)}
                            pagination={{
                                current: currentPage,
                                defaultCurrent: 1,
                                pageSize: DEFAULT_PAGE_SIZE,
                                total: total,
                                onChange: (page, pageSize) =>
                                    carregar(page, pageSize ?? DEFAULT_PAGE_SIZE),
                                showTotal: (registros: number, range: number[]) =>
                                    `${range[0]}-${range[1]} de ${total} registro(s)`,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Assuntos;
