import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    Divider,
    Typography,
    Form,
    Input,
    notification,
    Button,
    Modal,
    Popconfirm,
} from 'antd';
import { MdOutlineNotificationAdd } from 'react-icons/md';
import Handlebars from 'handlebars/dist/handlebars.min.js';
import { useNavigate } from 'react-router-dom';

import { ISkeletonValoresTemplate } from '../templates/ModalCriarSkeletonValores';
import { TypeValoresTemplate } from '../templates/ModalInformarValores';
import PreviewAppIframe from '../../components/previewAppIframe/PreviewAppIframe';
import SearchAplicativo, {
    obterUltimoAplicativoSelecionado,
} from '../../components/input/SearchAplicativo';
import InformarValores from '../templates/InformarValores';
import SearchAssunto from '../../components/input/SearchAssunto';
import SearchTemplate from '../../components/input/SearchTemplate';
import { IObterAplicativos } from '../../services/AplicativoSvc';
import TemplateSvc, { IObterTemplates } from '../../services/TemplateSvc';
import PreviewPopupNotif from './PreviewPopupNotif';
import NotificacaoTopicoSvc, {
    IEnviarNotificacaoTopicoRequest,
} from '../../services/NotificacaoTopicoSvc';
import { IObterAssuntos } from '../../services/AssuntoSvc';

const { Text, Paragraph, Title } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
        fontSize: 22,
    },
    textTemplateEngine: {
        marginTop: 10,
    },
    btnSalvar: {
        marginTop: 30,
    },
};

interface IFormValues {
    aplicativo: string;
    assunto: string;
    titulo: string;
    descricao: string;
}

function NovaNotificacaoTopico() {
    const navigate = useNavigate();
    const [form] = Form.useForm<IFormValues>();
    const valoresRef = useRef<any>(null);
    const [enviando, setEnviando] = useState(false);
    const [openModalEnviar, setOpenModalEnviar] = useState(false);

    const [skeletonValores, setSkeletonValores] = useState<ISkeletonValoresTemplate[]>([]);
    const [valores, setValores] = useState<TypeValoresTemplate>({});
    const [aplicativo, setAplicativo] = useState<IObterAplicativos | null>(
        obterUltimoAplicativoSelecionado()
    );
    const [template, setTemplate] = useState<IObterTemplates | null>(null);
    const [assunto, setAssunto] = useState<IObterAssuntos | null>(null);
    const [titulo, setTitulo] = useState<string>('');
    const [descricao, setDescricao] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const [textEditor, setTextEditor] = useState<string>('');
    const [textRendered, setTextRendered] = useState<string>('');

    const carregarTemplate = useCallback(async (templateId: string) => {
        try {
            const response = await TemplateSvc.obterTemplatePorId(templateId ?? '');
            setTextEditor(response.base);
            setSkeletonValores(JSON.parse(response.atributos));
            setValores({});
            valoresRef.current = JSON.parse(response.valores);
        } catch (error) {
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, []);

    async function enviarNotificacao() {
        try {
            if (!aplicativo?.chaveUnica || !assunto?.chaveUnica) {
                return;
            }

            setEnviando(true);

            const data: IEnviarNotificacaoTopicoRequest = {
                aplicativo: {
                    chaveUnica: aplicativo?.chaveUnica,
                },
                assunto: {
                    chaveUnica: assunto?.chaveUnica,
                },
                titulo,
                conteudo: descricao,
                tipo: 'APP e PUSH',
                html: textRendered,
                tecRender: template?.tecRender,
            };

            const response = await NotificacaoTopicoSvc.enviarNotificacaoTopico(data);

            navigate(`/enviadas/${response.id}`);

            notification.success({
                message: 'Notificação enviada com sucesso',
            });

            setEnviando(false);
        } catch (error) {
            setEnviando(false);
            setError(error.message);
        }
    }

    async function solicitarEnvio() {
        try {
            await form.validateFields();

            const camposVazios: string[] = [];
            for (const skeleton of skeletonValores) {
                if (skeleton.type === 'text') {
                    if (valores[skeleton.id] === undefined || valores[skeleton.id] === '') {
                        camposVazios.push(`Informe o campo "${skeleton.label}"`);
                    }
                }
            }

            if (camposVazios.length) {
                for (const message of camposVazios) {
                    notification.error({ message });
                }
                return;
            }

            setError(null);
            setOpenModalEnviar(true);
        } catch (error) {}
    }

    function carregarValoresTeste() {
        setValores(valoresRef.current);
    }

    useEffect(() => {
        if (template) {
            carregarTemplate(template.id);
        } else {
            setTextEditor('');
            setSkeletonValores([]);
        }
    }, [carregarTemplate, template]);

    useEffect(() => {
        try {
            const render = Handlebars.compile(textEditor, {
                noEscape: true,
            });
            const result = render(valores);
            setTextRendered(result);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }, [valores, textEditor]);

    return (
        <>
            <div className="base-page">
                <div className="base-page-content">
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <Row gutter={[16, 0]} align="middle">
                                        <Col className="center-content no-padding" span={1}>
                                            <MdOutlineNotificationAdd
                                                style={styles.iconPage}
                                                size={26}
                                            />
                                        </Col>
                                        <Col>
                                            <Text style={styles.textTitle}>Nova notificação</Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={24}>
                                    <Form
                                        form={form}
                                        labelCol={{ span: 24 }}
                                        autoComplete="off"
                                        onFinish={(val) => {}}
                                    >
                                        <Row>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item label="Aplicativo">
                                                            <SearchAplicativo
                                                                formItemName="aplicativo"
                                                                form={form}
                                                                value={aplicativo}
                                                                onChange={(val) => {
                                                                    form.resetFields(['assunto']);
                                                                    setAssunto(null);
                                                                    setAplicativo(val);
                                                                    if (!val) {
                                                                        form.resetFields([
                                                                            'template',
                                                                        ]);
                                                                        setTemplate(null);
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {aplicativo && (
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item label="Assunto">
                                                                <SearchAssunto
                                                                    formItemName="assunto"
                                                                    aplicativoId={aplicativo.id}
                                                                    value={assunto}
                                                                    topico={true}
                                                                    onChange={(val) =>
                                                                        setAssunto(val)
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}

                                            <Divider />
                                            {assunto && (
                                                <>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label="Título popup da notificação"
                                                            name="titulo"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Informe o título!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                maxLength={35}
                                                                value={titulo}
                                                                onChange={(e) =>
                                                                    setTitulo(e.target.value)
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row justify="end">
                                                            <PreviewPopupNotif
                                                                title={titulo}
                                                                description={descricao}
                                                            />
                                                        </Row>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="Descrição popup da notificação"
                                                            name="descricao"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Informe a descrição!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input.TextArea
                                                                showCount
                                                                maxLength={100}
                                                                value={descricao}
                                                                onChange={(e) =>
                                                                    setDescricao(e.target.value)
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Divider />
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Form.Item label="Template">
                                                                    <SearchTemplate
                                                                        formItemName="template"
                                                                        aplicativoId={
                                                                            aplicativo?.id
                                                                        }
                                                                        value={template}
                                                                        onChange={(val) =>
                                                                            setTemplate(val)
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Row justify="center">
                                                                    {!!template &&
                                                                        skeletonValores.length >
                                                                            0 && (
                                                                            <Form.Item
                                                                                label={<span />}
                                                                            >
                                                                                <Popconfirm
                                                                                    title="Qualquer valor já informado será substituído, tem certeza?"
                                                                                    onConfirm={
                                                                                        carregarValoresTeste
                                                                                    }
                                                                                    okText="Sim"
                                                                                    cancelText="Cancelar"
                                                                                >
                                                                                    <Button type="primary">
                                                                                        Carregar
                                                                                        valores de
                                                                                        exemplo
                                                                                    </Button>
                                                                                </Popconfirm>
                                                                            </Form.Item>
                                                                        )}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </Form>
                                    <Row>
                                        <Col span={24}>
                                            <InformarValores
                                                skeletonValores={skeletonValores}
                                                valores={valores}
                                                onChangeValores={(val) => setValores(val)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {template && (
                                <Row justify="start" align="bottom" style={styles.btnSalvar}>
                                    <Button type="primary" onClick={solicitarEnvio}>
                                        Enviar notificação
                                    </Button>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>

            <Col span={7}>
                {template && (
                    <PreviewAppIframe
                        textRendered={textRendered}
                        title={titulo ?? 'Título'}
                        type={template.tecRender}
                    />
                )}
            </Col>
            {openModalEnviar && (
                <Modal
                    title="Enviar notificação"
                    centered
                    visible={openModalEnviar}
                    onCancel={enviando ? undefined : () => setOpenModalEnviar(false)}
                    cancelButtonProps={{ hidden: true }}
                    footer={[
                        <Button
                            key={1}
                            type="default"
                            onClick={() => setOpenModalEnviar(false)}
                            disabled={enviando}
                        >
                            Fechar
                        </Button>,
                        !error && (
                            <Button
                                key={2}
                                type="primary"
                                onClick={() => enviarNotificacao()}
                                loading={enviando}
                            >
                                {enviando ? 'Enviando...' : 'Enviar'}
                            </Button>
                        ),
                    ]}
                >
                    {error ? (
                        <>
                            <Title level={4} type="danger">
                                Um erro ocorreu!
                            </Title>
                            <Paragraph>{error}</Paragraph>
                        </>
                    ) : (
                        <>
                            <Title level={4} type="danger">
                                Aviso!
                            </Title>
                            <Title level={5}>
                                <Paragraph>
                                    Uma notificação após ser enviada não pode ser alterada, tenha
                                    certeza que o conteúdo informado está correto.
                                </Paragraph>
                            </Title>

                            <Paragraph>
                                Confirma envio de notificação para o aplicativo: "{aplicativo?.nome}
                                "?
                            </Paragraph>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
}

export default NovaNotificacaoTopico;
