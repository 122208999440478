import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    Select,
    notification,
    Empty,
    SelectProps,
    Checkbox,
    Form,
    FormInstance,
} from 'antd';

import TemplateSvc, { IObterTemplates } from '../../services/TemplateSvc';
import TagSituacao from '../tag/TagSituacao';

const { Option } = Select;

interface ISearchTemplateProps extends SelectProps {
    aplicativoId?: string;
    formItemName?: string;
    form?: FormInstance;
    filtroExcluidos?: boolean;
    value: IObterTemplates | null;
    onChange: (val: IObterTemplates | null) => void;
}

function SearchTemplate({
    filtroExcluidos,
    formItemName,
    value,
    onChange,
    form,
    aplicativoId,
    ...props
}: ISearchTemplateProps) {
    const timeSearch = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState<IObterTemplates[]>([]);
    const [textoPesquisa, setTextoPesquisa] = useState('');
    const [excluidos, setExcluidos] = useState(false);

    const carregarListaTemplates = useCallback(async () => {
        try {
            if (!aplicativoId) {
                return;
            }

            setLoading(true);
            const response = await TemplateSvc.obterTemplates({
                aplicativoId,
                page: 1,
                limit: 10,
                excluido: excluidos,
                textoPesquisa,
            });

            setTemplates(response.result);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [aplicativoId, excluidos, textoPesquisa]);

    function onSelect() {
        clearTimeout(timeSearch.current);
        setTextoPesquisa('');
    }

    useEffect(() => {
        clearTimeout(timeSearch.current);
        timeSearch.current = setTimeout(() => {
            carregarListaTemplates();
        }, 600);
    }, [carregarListaTemplates, textoPesquisa]);

    useEffect(() => {
        if (value) {
            setTemplates((val) => {
                const existe = val.some(({ id }) => id === value.id);
                if (!existe) {
                    const cloneArray = [...val];
                    cloneArray.push(value);
                    return cloneArray;
                }
                return val;
            });
            if (form && formItemName) {
                form?.setFieldsValue({
                    [formItemName as string]: value.id,
                });
            }
        }
    }, [form, formItemName, value]);

    return (
        <Form.Item
            id={formItemName}
            name={formItemName}
            noStyle
            rules={[
                {
                    required: true,
                    message: 'Informe o template!',
                },
            ]}
        >
            <Select
                showSearch
                placeholder="Digite o nome do app..."
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                loading={loading}
                searchValue={textoPesquisa}
                onSearch={(val) => setTextoPesquisa(val)}
                onSelect={() => onSelect()}
                notFoundContent={
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nada encontrado" />
                }
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                    <Col>
                        <Row justify="end">
                            {filtroExcluidos && (
                                <Checkbox
                                    checked={excluidos}
                                    onChange={(e) => setExcluidos(e.target.checked)}
                                    style={{ paddingBottom: 10 }}
                                >
                                    Excluídos?
                                </Checkbox>
                            )}
                        </Row>

                        {menu}
                    </Col>
                )}
                {...props}
                value={value?.id}
                onChange={(val) => onChange(templates.find(({ id }) => id === val) ?? null)}
            >
                {templates.map(({ id, nome, excluidoEm }) => (
                    <Option key={id} value={id}>
                        <Row align="middle">
                            <Col span={12}>{nome}</Col>
                            <Col span={12}>
                                <Row justify="end">
                                    <TagSituacao ativo={!excluidoEm} />
                                </Row>
                            </Col>
                        </Row>
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default SearchTemplate;
