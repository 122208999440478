import { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Divider,
    notification,
    Typography,
    Form,
    Input,
    Button,
    Spin,
    Popover,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Keycloak from '../../utils/Keycloak';
import { HiOutlinePuzzle } from 'react-icons/hi';

import AplicativoSvc, {
    ICriarAlterarAplicativoRequest,
    ICriarAplicativoResponse,
    IObterAplicativoPorIdResponse,
} from '../../services/AplicativoSvc';
import DataFormat from '../../utils/DataFormat';
import FieldValidator from '../../utils/FieldValidator';
import TagSituacao from '../../components/tag/TagSituacao';

const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
    },
    textTitleAviso: {
        color: '#e68302',
    },
};

interface IFormValues extends IObterAplicativoPorIdResponse {
    excluidoEmDisplay: string;
    situacao: 'Excluído' | 'Ativo';
}

function AplicativoEdicao() {
    const params = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm<IFormValues>();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [aplicativo, setAplicativo] = useState<IObterAplicativoPorIdResponse | null>(null);

    async function salvarAplicativo(formValues: IFormValues) {
        try {
            setSaving(true);
            if (!aplicativo && params.id !== 'novo') {
                throw new Error('Nenhum aplicativo foi selecionado para edição');
            }

            let response: ICriarAplicativoResponse | null = null;

            if (params.id === 'novo') {
                response = await AplicativoSvc.criarAplicativo({
                    nome: formValues.nome,
                    chaveUnica: formValues.chaveUnica,
                    credencialFirebase: formValues.credencialFirebase,
                });
            } else {
                response = await AplicativoSvc.alterarAplicativo(params.id ?? '', {
                    nome: formValues.nome,
                    chaveUnica: formValues.chaveUnica,
                    credencialFirebase: formValues.credencialFirebase,
                });
            }

            setSaving(false);

            notification.success({ message: 'Aplicativo salvo com sucesso!' });

            if (params.id === 'novo') {
                navigate(`/aplicativos/${response.id}`);
            } else {
                carregar();
            }
        } catch (error) {
            setSaving(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    function permiteEdicao(field: keyof ICriarAlterarAplicativoRequest) {
        if (params.id === 'novo') {
            return true;
        } else {
            if (field === 'nome' && !aplicativo?.excluidoEm && Keycloak.hasSuperAdmin()) {
                return true;
            }
            if (field === 'chaveUnica' && !aplicativo?.excluidoEm && Keycloak.hasSuperAdmin()) {
                return true;
            }
            if (field === 'credencialFirebase' && !aplicativo?.excluidoEm) {
                return true;
            }
        }

        return false;
    }

    const carregar = useCallback(async () => {
        try {
            if (params.id === 'novo') {
                return;
            }
            setLoading(true);
            const response = await AplicativoSvc.obterAplicativoPorId(params.id ?? '');
            setAplicativo(response);
            form.resetFields();
            form.setFieldsValue({
                ...response,
                excluidoEmDisplay: DataFormat.formatarDataHoraCompleta(response.excluidoEm),
                situacao: response.excluidoEm ? 'Excluído' : 'Ativo',
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [form, params.id]);

    useEffect(() => {
        carregar();
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Spin spinning={loading}>
                    <Row>
                        <Col span={12}>
                            <Row gutter={[16, 0]} align="middle">
                                <Col className="center-content no-padding" span={1}>
                                    <HiOutlinePuzzle style={styles.iconPage} size={26} />
                                </Col>
                                <Col span={6}>
                                    <Text style={styles.textTitle}>Aplicativo</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={10}>
                            <Spin spinning={loading}>
                                <Form
                                    form={form}
                                    name="basic"
                                    labelCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    onFinish={(val) => salvarAplicativo(val)}
                                    autoComplete="off"
                                >
                                    {params.id !== 'novo' && (
                                        <Row gutter={[16, 0]}>
                                            <Col span={18}>
                                                <Form.Item label="ID" name="id">
                                                    <Input readOnly className="input-read-only" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item label="Situação" name="situacao">
                                                    <TagSituacao
                                                        ativo={!aplicativo?.excluidoEm}
                                                        type="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}

                                    {!!aplicativo?.excluidoEm && (
                                        <Form.Item label="Dt. Exclusão" name="excluidoEmDisplay">
                                            <Input readOnly className="input-read-only" />
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        label="Nome"
                                        name="nome"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Informe o nome do aplicativo!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            maxLength={100}
                                            readOnly={!permiteEdicao('nome')}
                                            className={
                                                permiteEdicao('nome') ? '' : 'input-read-only'
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Chave única"
                                        name="chaveUnica"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Informe a chave única do aplicativo!',
                                            },
                                            {
                                                message: 'Chave única inválida',
                                                validator: async (_, value) => {
                                                    const val = String(value);
                                                    if (
                                                        !FieldValidator.validaPadraoChaveUnica(val)
                                                    ) {
                                                        throw new Error('Erro');
                                                    }
                                                },
                                            },
                                        ]}
                                        normalize={(value: string) => (value || '').toLowerCase()}
                                    >
                                        <Input
                                            maxLength={36}
                                            placeholder='Exemplo "app-teste"'
                                            readOnly={!permiteEdicao('chaveUnica')}
                                            className={
                                                permiteEdicao('chaveUnica') ? '' : 'input-read-only'
                                            }
                                            suffix={
                                                <Popover
                                                    content={ConteudoPopover}
                                                    title={
                                                        <span style={styles.textTitleAviso}>
                                                            Aviso!
                                                        </span>
                                                    }
                                                    placement="topRight"
                                                >
                                                    <InfoCircleOutlined
                                                        style={styles.textTitleAviso}
                                                    />
                                                </Popover>
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Credenciais Firebase"
                                        name="credencialFirebase"
                                    >
                                        <Input.TextArea
                                            readOnly={!permiteEdicao('credencialFirebase')}
                                            className={
                                                permiteEdicao('credencialFirebase')
                                                    ? 'input-text-break-all'
                                                    : 'input-text-break-all input-read-only'
                                            }
                                            rows={10}
                                        />
                                    </Form.Item>

                                    {!aplicativo?.excluidoEm && (
                                        <Form.Item>
                                            <Row justify="end">
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={saving}
                                                    >
                                                        Salvar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    )}
                                </Form>
                            </Spin>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div>
    );
}

const ConteudoPopover = (
    <div>
        <p>
            Chave única é utilizada em integrações, <br />
            alterá-la após a criação pode ocasionar quebra <br />
            nas integrações realizadas com a plataforma, <br />
            esteja ciente que alterações podem ser necessárias <br />
            nos consumidores da API.
        </p>
    </div>
);

export default AplicativoEdicao;
