import React from 'react';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.min.css';

import App from './App';
// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (errObj, ...args) => {
    if (typeof errObj === 'string' && args.includes('findDOMNode')) {
        return;
    }
    consoleError(errObj, ...args);
};

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider direction="ltr">
            <App />
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
