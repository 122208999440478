import { useEffect, useState } from 'react';
import Router from './Router';
import Keycloak from './utils/Keycloak';

import SplashScreen from './components/splashScreen/SplashScreen';
import Api from './api/Api';

function App() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    async function carregarAplicacao() {
        try {
            setLoading(true);
            await Api.load();
            await Keycloak.validateSession();
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        carregarAplicacao();
    }, []);

    if (loading || error) {
        return <SplashScreen error={error} />;
    }

    return <Router />;
}

export default App;
