import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { BiCodeCurly } from 'react-icons/bi';
import { AiOutlineReload } from 'react-icons/ai';

import ModalShowCode from '../modal/ModalShowCode';
import { TypeTipoTemplate } from '../../services/TemplateSvc';

const { Option } = Select;

interface IDeviceSize {
    name: string;
    width: number;
    height: number;
}

interface IPreviewAppIframeProps {
    textRendered: string;
    type: TypeTipoTemplate;
    title?: string;
}

function PreviewAppIframe({ textRendered, type, title }: IPreviewAppIframeProps) {
    const [form] = Form.useForm();
    const timeSearch = useRef<any>(null);
    const [device, setDevice] = useState<IDeviceSize>(devices[0]);
    const [queryString, setQueryString] = useState('');
    const [showPreviewCode, setShowPreviewCode] = useState(false);

    useEffect(() => {
        clearTimeout(timeSearch.current);
        timeSearch.current = setTimeout(() => {
            if (type === 'yaml-handlebars') {
                const query = new URLSearchParams({
                    title: title ?? '',
                    yaml: textRendered,
                }).toString();
                setQueryString(query);
            } else {
                const query = new URLSearchParams({
                    title: title ?? '',
                    html: textRendered,
                }).toString();
                setQueryString(query);
            }
        }, 600);
    }, [title, textRendered, type]);

    return (
        <>
            <div style={styles.rootPreview}>
                <div style={styles.rootResolucaoPreview}>
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{ resolucao: devices[0].name }}
                        autoComplete="off"
                        style={{ width: '100%' }}
                    >
                        <Row>
                            <Col span={6} />
                            <Col span={12}>
                                <Form.Item
                                    id="resolucao"
                                    label="Resolução preview"
                                    labelCol={{ span: 24 }}
                                    className="no-margin"
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        value={device.name}
                                        onChange={(val) =>
                                            setDevice(
                                                devices.find(({ name }) => val === name) ??
                                                    devices[0]
                                            )
                                        }
                                    >
                                        {devices.map(({ name }) => (
                                            <Option key={name} value={name}>
                                                {name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Row justify="center">
                                    <Col span={20}>
                                        <Row justify="space-around">
                                            <Form.Item
                                                label={<span />}
                                                labelCol={{ span: 24 }}
                                                className="no-margin"
                                            >
                                                <Button
                                                    type="dashed"
                                                    shape="circle"
                                                    icon={
                                                        <AiOutlineReload
                                                            size={18}
                                                            color="#1890ff"
                                                        />
                                                    }
                                                    onClick={() =>
                                                        setQueryString((val) => `${val}&reload`)
                                                    }
                                                    className="center-content"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={<span />}
                                                labelCol={{ span: 24 }}
                                                className="no-margin"
                                            >
                                                <Button
                                                    type="dashed"
                                                    shape="circle"
                                                    icon={<BiCodeCurly size={18} color="#1890ff" />}
                                                    onClick={() => setShowPreviewCode(true)}
                                                    className="center-content"
                                                />
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div style={styles.containerIframe}>
                    <iframe
                        src={`${window.location.origin}/preview/expo-app-render/index.html?${queryString}`}
                        title="render"
                        style={{
                            ...styles.iframe,
                            ...calcDimension(device.width, device.height),
                        }}
                    ></iframe>
                </div>
            </div>
            <ModalShowCode
                visible={showPreviewCode}
                onCancel={() => setShowPreviewCode(false)}
                code={textRendered}
                lang={type === 'yaml-handlebars' ? 'yaml' : 'html'}
            />
        </>
    );
}

function calcDimension(width: number, height: number) {
    const aspectRatio = width / height;
    const calcHeight = window.screen.height * 0.6;
    const calcWidth = aspectRatio * calcHeight;
    return {
        width: calcWidth,
        height: calcHeight,
    };
}

const styles: { [key: string]: CSSProperties } = {
    iframe: {
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 15px 5px rgba(0,0,0,0.2)',
        background: '#FFF',
        borderRadius: 5,
    },
    rootPreview: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        background: '#FFF',
    },
    rootResolucaoPreview: {
        display: 'flex',
        width: '100%',
        marginTop: 5,
    },
    containerIframe: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
};

const devices: IDeviceSize[] = [
    {
        name: 'IPhone 12',
        width: 390,
        height: 844,
    },
    {
        name: 'IPhone 6',
        width: 375,
        height: 667,
    },
    {
        name: 'Pixel 5',
        width: 393,
        height: 851,
    },
    {
        name: 'Galaxy A7',
        width: 390,
        height: 702,
    },
    {
        name: 'Galaxy S20 Ultra',
        width: 412,
        height: 915,
    },
];

export default PreviewAppIframe;
