import { Modal } from 'antd';
import { useState } from 'react';

import { ISkeletonValoresTemplate } from './ModalCriarSkeletonValores';
import InformarValores from './InformarValores';

export type TypeValoresTemplate = { [key: string]: string | TypeValoresTemplate[] };

interface IModalInformarValoresProps {
    visible: boolean;
    onCancel: () => void;
    onOk: (valor: TypeValoresTemplate) => void;
    skeletonValores: ISkeletonValoresTemplate[];
    valores: TypeValoresTemplate;
}

export default function ModalInformarValores({
    visible,
    onCancel,
    onOk,
    skeletonValores,
    valores,
}: IModalInformarValoresProps) {
    const [valoresEdit, setValoresEdit] = useState<TypeValoresTemplate>(valores);

    function retornarValores() {
        onOk(valoresEdit);
        onCancel();
    }

    return (
        <Modal
            title="Informar valores"
            visible={visible}
            onOk={retornarValores}
            onCancel={onCancel}
            width="50%"
        >
            <InformarValores
                skeletonValores={skeletonValores}
                valores={valoresEdit}
                onChangeValores={(val) => setValoresEdit(val)}
            />
        </Modal>
    );
}
