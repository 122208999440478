import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    notification,
    Popover,
    Row,
    Spin,
    Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import SearchAplicativo from '../../components/input/SearchAplicativo';
import TagSituacao from '../../components/tag/TagSituacao';
import AssuntoSvc, {
    ICriarAlterarAssuntoRequest,
    ICriarAssuntoResponse,
    IObterAssuntoPorIdResponse,
} from '../../services/AssuntoSvc';
import FieldValidator from '../../utils/FieldValidator';
import Keycloak from '../../utils/Keycloak';
import DataFormat from '../../utils/DataFormat';
import { IObterAplicativos } from '../../services/AplicativoSvc';

const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    iconPage: {
        color: 'black',
    },
    textTitle: {
        fontSize: 20,
    },
};

interface IFormValues extends IObterAssuntoPorIdResponse {
    excluidoEmDisplay: string;
    situacao: 'Excluído' | 'Ativo';
}

function AssuntoEdicao() {
    const [loading, setLoading] = useState(false);
    const [aplicativo, setAplicativoId] = useState<IObterAplicativos | null>(null);
    const [form] = Form.useForm<IFormValues>();
    const params = useParams();
    const [assunto, setAssunto] = useState<IObterAssuntoPorIdResponse | null>(null);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();

    async function salvarAssunto(formValues: IFormValues) {
        try {
            setSaving(true);

            if (!assunto && params.id !== 'novo') {
                throw new Error('Nenhum assunto foi selecionado para edição');
            }

            let response: ICriarAssuntoResponse | null = null;

            if (params.id === 'novo') {
                response = await AssuntoSvc.criarAssunto({
                    aplicativoId: formValues.aplicativoId,
                    titulo: formValues.titulo,
                    descricao: formValues.descricao,
                    identificadorImagem: formValues.identificadorImagem,
                    chaveUnica: formValues.chaveUnica,
                    chaveAcaoAPP: formValues.chaveAcaoAPP,
                    opcional: formValues.opcional ?? false,
                    topico: formValues.topico ?? false,
                });
            } else {
                response = await AssuntoSvc.alterarAssunto(params.id ?? '', {
                    aplicativoId: formValues.aplicativoId,
                    titulo: formValues.titulo,
                    descricao: formValues.descricao,
                    identificadorImagem: formValues.identificadorImagem,
                    chaveUnica: formValues.chaveUnica,
                    chaveAcaoAPP: formValues.chaveAcaoAPP,
                    opcional: formValues.opcional,
                    topico: formValues.topico,
                });
            }

            setSaving(false);

            notification.success({ message: 'Assunto salvo com sucesso!' });

            if (params.id === 'novo') {
                navigate(`/assuntos/${response.id}`);
            } else {
                carregar();
            }
        } catch (error) {
            setSaving(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }

    function permiteEdicao(field: keyof ICriarAlterarAssuntoRequest) {
        if (params.id === 'novo') {
            return true;
        } else {
            if (
                field === 'titulo' &&
                !assunto?.excluidoEm &&
                (Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp)
            ) {
                return true;
            }
            if (
                field === 'chaveUnica' &&
                !assunto?.excluidoEm &&
                (Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp)
            ) {
                return true;
            }
            if (
                field === 'chaveAcaoAPP' &&
                !assunto?.excluidoEm &&
                (Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp)
            ) {
                return true;
            }
            if (
                field === 'identificadorImagem' &&
                !assunto?.excluidoEm &&
                (Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp)
            ) {
                return true;
            }
            if (
                field === 'descricao' &&
                !assunto?.excluidoEm &&
                (Keycloak.hasSuperAdmin() || Keycloak.hasAdminApp)
            ) {
                return true;
            }
        }

        return false;
    }

    const carregar = useCallback(async () => {
        try {
            if (params.id === 'novo') {
                return;
            }

            setLoading(true);
            const response = await AssuntoSvc.obterAssuntoPorId(params.id ?? '');
            setAssunto(response);
            form.resetFields();
            form.setFieldsValue({
                ...response,
                excluidoEmDisplay: DataFormat.formatarDataHoraCompleta(response.excluidoEm),
                situacao: response.excluidoEm ? 'Excluído' : 'Ativo',
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [form, params.id]);

    useEffect(() => {
        carregar();
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Row>
                    <Col span={12}>
                        <Row gutter={[16, 0]} align="middle">
                            <Col className="center-content no-padding" span={1}>
                                <HiOutlineSpeakerphone style={styles.iconPage} size={26} />
                            </Col>
                            <Col span={6}>
                                <Text style={styles.textTitle}>Assunto</Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={10}>
                        <Spin spinning={loading}>
                            <Form
                                form={form}
                                name="basic"
                                labelCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                onFinish={(val) => salvarAssunto(val)}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Aplicativo" labelCol={{ span: 24 }}>
                                            <SearchAplicativo
                                                formItemName="aplicativoId"
                                                value={aplicativo}
                                                onChange={(val) => setAplicativoId(val)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {params.id !== 'novo' && (
                                    <Row gutter={[8, 0]}>
                                        <Col span={21}>
                                            <Form.Item label="ID" name="id">
                                                <Input readOnly className="input-read-only" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item label="Situação" name="situacao">
                                                <TagSituacao
                                                    ativo={!assunto?.excluidoEm}
                                                    type="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                {!!assunto?.excluidoEm && (
                                    <Form.Item label="Dt. Exclusão" name="excluidoEmDisplay">
                                        <Input readOnly className="input-read-only" />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    label="Título"
                                    name="titulo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Informe o título do assunto!',
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        readOnly={!permiteEdicao('titulo')}
                                        className={permiteEdicao('titulo') ? '' : 'input-read-only'}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Chave única"
                                    name="chaveUnica"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Informe a chave única do aplicativo!',
                                        },
                                        {
                                            message: 'Chave única inválida',
                                            validator: async (_, value) => {
                                                const val = String(value);
                                                if (!FieldValidator.validaPadraoChaveUnica(val)) {
                                                    throw new Error('Erro');
                                                }
                                            },
                                        },
                                    ]}
                                    normalize={(value: string) => (value || '').toLowerCase()}
                                >
                                    <Input
                                        maxLength={36}
                                        placeholder='Exemplo "app-teste"'
                                        readOnly={!permiteEdicao('chaveUnica')}
                                        className={
                                            permiteEdicao('chaveUnica') ? '' : 'input-read-only'
                                        }
                                        suffix={
                                            <Popover
                                                content={ConteudoPopover}
                                                title={
                                                    <span style={styles.textTitleAviso}>
                                                        Aviso!
                                                    </span>
                                                }
                                                placement="topRight"
                                            >
                                                <InfoCircleOutlined style={styles.textTitleAviso} />
                                            </Popover>
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Chave de ação de uso do APP"
                                    name="chaveAcaoAPP"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Informe a chave de ação de uso do APP!',
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        readOnly={!permiteEdicao('chaveAcaoAPP')}
                                        className={
                                            permiteEdicao('chaveAcaoAPP') ? '' : 'input-read-only'
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Identificador de imagem"
                                    name="identificadorImagem"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Informe o identificador de imagem!',
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        readOnly={!permiteEdicao('identificadorImagem')}
                                        className={
                                            permiteEdicao('identificadorImagem')
                                                ? ''
                                                : 'input-read-only'
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Descrição"
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Informe a descrição do assunto!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        readOnly={!permiteEdicao('descricao')}
                                        className={
                                            permiteEdicao('descricao')
                                                ? 'input-text-break-all'
                                                : 'input-text-break-all input-read-only'
                                        }
                                        rows={3}
                                    />
                                </Form.Item>

                                <Row gutter={[14, 0]}>
                                    <Col>
                                        <Form.Item name="opcional" valuePropName="checked">
                                            <Checkbox>Opcional</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="topico" valuePropName="checked">
                                            <Checkbox>Topico</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {!assunto?.excluidoEm && (
                                    <Form.Item>
                                        <Row justify="end">
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={saving}
                                                >
                                                    Salvar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                )}
                            </Form>
                        </Spin>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

const ConteudoPopover = (
    <div>
        <p>
            Chave única é utilizada em integrações, <br />
            alterá-la após a criação pode ocasionar quebra <br />
            nas integrações realizadas com a plataforma, <br />
            esteja ciente que alterações podem ser necessárias <br />
            nos consumidores da API.
        </p>
    </div>
);

export default AssuntoEdicao;
