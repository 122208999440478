import { Button, Col, Form, Input, Row, Space } from 'antd';
import { CSSProperties } from 'react';
import { DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { ISkeletonValoresTemplate } from './ModalCriarSkeletonValores';

export type TypeValoresTemplate = { [key: string]: string | TypeValoresTemplate[] };

const styles: { [key: string]: CSSProperties } = {
    formItemGrid: {
        marginBottom: 3,
    },
    labelObrigatoria: {
        color: 'red',
    },
};

interface IInformarValoresProps {
    skeletonValores: ISkeletonValoresTemplate[];
    valores: TypeValoresTemplate;
    onChangeValores: (valor: TypeValoresTemplate) => void;
}

export default function InformarValores({
    skeletonValores,
    valores,
    onChangeValores,
}: IInformarValoresProps) {
    function onChangeValue(id: string, value: string) {
        const newList = JSON.parse(JSON.stringify(valores));
        newList[id] = value;
        onChangeValores(newList);
    }

    function addItemGrid(id: string) {
        const newList = JSON.parse(JSON.stringify(valores));
        if (valores[id] === undefined) {
            newList[id] = [];
        }
        const gridIds = skeletonValores.find((e) => e.id === id)?.grid?.map((g) => g.id);
        let grid: any = {};
        for (let gridId of gridIds ?? []) {
            grid[gridId] = '';
        }
        newList[id].push(grid);
        onChangeValores(newList);
    }

    function remItemGrid(id: string, index: number) {
        let newList: TypeValoresTemplate = JSON.parse(JSON.stringify(valores));
        (newList[id] as []).splice(index, 1);
        onChangeValores(newList);
    }

    function arrayMoveIndex(arr: [], fromIndex: number, toIndex: number) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    }

    function onChangeValueGrid(rootId: string, index: number, propName: string, value: string) {
        const newList = JSON.parse(JSON.stringify(valores));
        newList[rootId][index][propName] = value;
        onChangeValores(newList);
    }

    function setOrderUp(rootId: string, index: number) {
        if (index === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(valores));
        newList[rootId] = arrayMoveIndex(newList[rootId], index, index - 1);
        onChangeValores(newList);
    }

    function setOrderDown(rootId: string, index: number) {
        const newList = JSON.parse(JSON.stringify(valores));
        if (index === newList[rootId].length - 1) {
            return;
        }
        newList[rootId] = arrayMoveIndex(newList[rootId], index, index + 1);
        onChangeValores(newList);
    }

    return (
        <Form labelCol={{ span: 24 }} autoComplete="off">
            <Row gutter={[0, 16]}>
                {skeletonValores?.map(({ id: rootId, type, label, grid }) => (
                    <Col key={rootId} span={24}>
                        {type === 'text' ? (
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        id={rootId}
                                        label={
                                            <span>
                                                <span style={styles.labelObrigatoria}>*</span>{' '}
                                                {label}
                                            </span>
                                        }
                                        className="no-margin"
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input
                                            value={String(valores[rootId] ?? '')}
                                            onChange={(e) => onChangeValue(rootId, e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col span={12}>{label}</Col>
                                        <Col span={12}>
                                            <Row justify="end">
                                                <Button
                                                    type="primary"
                                                    onClick={() => addItemGrid(rootId)}
                                                >
                                                    Add
                                                </Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        {((valores[rootId] ?? []) as [])?.map((obj, index) => (
                                            <Col key={index} span={24}>
                                                <Row gutter={[5, 10]}>
                                                    {grid?.map(({ id, label }) => (
                                                        <Col
                                                            key={`${rootId}-${id}`}
                                                            span={spanCalc(grid)}
                                                            style={styles.formItemGrid}
                                                        >
                                                            <Form.Item
                                                                id={`${rootId}-${id}`}
                                                                label={
                                                                    <span>
                                                                        <span
                                                                            style={
                                                                                styles.labelObrigatoria
                                                                            }
                                                                        >
                                                                            *
                                                                        </span>{' '}
                                                                        {label}
                                                                    </span>
                                                                }
                                                                className="no-margin"
                                                                noStyle={index > 0}
                                                                labelCol={{ span: 24 }}
                                                            >
                                                                <Input
                                                                    value={String(obj[id] ?? '')}
                                                                    onChange={(e) =>
                                                                        onChangeValueGrid(
                                                                            rootId,
                                                                            index,
                                                                            id,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    ))}
                                                    <Col
                                                        span={spanCalc(grid)}
                                                        style={styles.formItemGrid}
                                                    >
                                                        <Form.Item
                                                            label={<span />}
                                                            className="no-margin"
                                                            noStyle={index > 0}
                                                        >
                                                            <Row justify="center">
                                                                <Space size="middle">
                                                                    <Button
                                                                        type="dashed"
                                                                        shape="circle"
                                                                        icon={<ArrowUpOutlined />}
                                                                        onClick={() =>
                                                                            setOrderUp(
                                                                                rootId,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        type="dashed"
                                                                        shape="circle"
                                                                        icon={<ArrowDownOutlined />}
                                                                        onClick={() =>
                                                                            setOrderDown(
                                                                                rootId,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        type="dashed"
                                                                        shape="circle"
                                                                        icon={<DeleteOutlined />}
                                                                        onClick={() =>
                                                                            remItemGrid(
                                                                                rootId,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </Space>
                                                            </Row>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Col>
                ))}
            </Row>
        </Form>
    );
}

function spanCalc(arr?: Array<any>) {
    const length = Array.isArray(arr) ? arr.length : 0;
    return Math.floor(24 / (length + 1));
}
