import { Button, message, Modal } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import EditorPreviewRender from '../editorPreviewRender/EditorPreviewRender';

interface IModalShowCode {
    code: string;
    lang: 'yaml' | 'html';
    visible: boolean;
    onCancel: () => void;
}

function ModalShowCode({ code, lang, visible, onCancel }: IModalShowCode) {
    return (
        <Modal
            title="Preview Yaml"
            centered
            visible={visible}
            onCancel={onCancel}
            cancelButtonProps={{ hidden: true }}
            footer={[
                <Button
                    key={1}
                    type="link"
                    icon={<CopyOutlined />}
                    onClick={() => copyToClipboard(code)}
                />,
                <Button key={2} type="default" onClick={onCancel}>
                    Fechar
                </Button>,
            ]}
            width="100%"
        >
            <EditorPreviewRender value={code} readonly lang={lang} />
        </Modal>
    );
}

function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    message.success({
        content: 'Copied to clipboard',
        duration: 1,
    });
}

export default ModalShowCode;
