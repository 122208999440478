import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    Select,
    notification,
    Empty,
    SelectProps,
    Checkbox,
    Form,
    FormInstance,
} from 'antd';

import AssuntoSvc, { IObterAssuntos } from '../../services/AssuntoSvc';
import TagSituacao from '../tag/TagSituacao';

const { Option } = Select;

interface ISearchAssuntoProps extends SelectProps {
    aplicativoId?: string;
    formItemName?: string;
    topico?: boolean;
    chaveAcaoAPP?: string;
    form?: FormInstance;
    filtroExcluidos?: boolean;
    value: IObterAssuntos | null;
    onChange: (val: IObterAssuntos | null) => void;
}

function SearchAssunto({
    filtroExcluidos,
    formItemName,
    value,
    onChange,
    form,
    aplicativoId,
    topico,
    chaveAcaoAPP,
    ...props
}: ISearchAssuntoProps) {
    const timeSearch = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [assuntos, setAssuntos] = useState<IObterAssuntos[]>([]);
    const [textoPesquisa, setTextoPesquisa] = useState('');
    const [excluidos, setExcluidos] = useState(false);

    const carregarListaAssuntos = useCallback(async () => {
        try {
            if (!aplicativoId) {
                return;
            }

            setLoading(true);
            const response = await AssuntoSvc.obterAssuntos2({
                aplicativoId,
                page: 1,
                limit: 10,
                assuntoExcluido: excluidos,
                titulo: textoPesquisa,
                topico,
                chaveAcaoAPP,
            });

            setAssuntos(response.result);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [aplicativoId, chaveAcaoAPP, excluidos, textoPesquisa, topico]);

    function onSelect() {
        clearTimeout(timeSearch.current);
        setTextoPesquisa('');
    }

    useEffect(() => {
        clearTimeout(timeSearch.current);
        timeSearch.current = setTimeout(() => {
            carregarListaAssuntos();
        }, 600);
    }, [carregarListaAssuntos, textoPesquisa]);

    useEffect(() => {
        if (value) {
            setAssuntos((val) => {
                const existe = val.some(({ id }) => id === value.id);
                if (!existe) {
                    const cloneArray = [...val];
                    cloneArray.push(value);
                    return cloneArray;
                }
                return val;
            });
            if (form && formItemName) {
                form?.setFieldsValue({
                    [formItemName as string]: value.id,
                });
            }
        }
    }, [form, formItemName, value]);

    return (
        <Form.Item
            id={formItemName}
            name={formItemName}
            noStyle
            rules={[
                {
                    required: true,
                    message: 'Informe o assunto!',
                },
            ]}
        >
            <Select
                showSearch
                placeholder="Digite o nome do app..."
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                loading={loading}
                searchValue={textoPesquisa}
                onSearch={(val) => setTextoPesquisa(val)}
                onSelect={() => onSelect()}
                notFoundContent={
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nada encontrado" />
                }
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                    <Col>
                        <Row justify="end">
                            {filtroExcluidos && (
                                <Checkbox
                                    checked={excluidos}
                                    onChange={(e) => setExcluidos(e.target.checked)}
                                    style={{ paddingBottom: 10 }}
                                >
                                    Excluídos?
                                </Checkbox>
                            )}
                        </Row>

                        {menu}
                    </Col>
                )}
                {...props}
                value={value?.id}
                onChange={(val) => onChange(assuntos.find(({ id }) => id === val) ?? null)}
            >
                {assuntos.map(({ id, titulo, excluidoEm }) => (
                    <Option key={id} value={id}>
                        <Row align="middle">
                            <Col span={12}>{titulo}</Col>
                            <Col span={12}>
                                <Row justify="end">
                                    <TagSituacao ativo={!excluidoEm} />
                                </Row>
                            </Col>
                        </Row>
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default SearchAssunto;
