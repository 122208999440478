import { CSSProperties, memo } from 'react';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-sqlserver';

const styles: { [key: string]: CSSProperties } = {
    codeEditor: {
        width: '100%',
        minHeight: '30vh',
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 3,
    },
};

interface IEditorPreviewYamlRenderProps {
    value?: string;
    onChange?: (value: string) => void;
    readonly?: boolean;
    lang: 'json' | 'yaml' | 'html';
}

function EditorPreviewRender({ value, onChange, readonly, lang }: IEditorPreviewYamlRenderProps) {
    return (
        <AceEditor
            mode={lang}
            theme="sqlserver"
            value={value}
            onChange={onChange ? (e) => onChange(e) : undefined}
            editorProps={{ $blockScrolling: true }}
            fontSize={16}
            style={styles.codeEditor}
            readOnly={readonly}
            tabSize={2}
            showPrintMargin={false}
        />
    );
}

export default memo(EditorPreviewRender);
