import { Routes, Route, Navigate } from 'react-router-dom';

//Pages
import Dashboard from '../dashboard/Dashboard';
import Aplicativo from '../aplicativo/Aplicativos';
import AplicativoEdicao from '../aplicativo/AplicativoEdicao';
import Assunto from '../assunto/Assuntos';
import NovaNotificacaoTopico from '../notificacoes/NovaNotificacaoTopico';
import NotificacoesTopico from '../notificacoes/NotificacoesTopico';
import NotificacaoTopicoEnviada from '../notificacoes/NotificacaoTopicoEnviada';
import Templates from '../templates/Templates';
import TemplateEdicao from '../templates/TemplateEdicao';
import AssuntoEdicao from '../assunto/AssuntoEdicao';

function MenuRouter() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/aplicativos" element={<Aplicativo />} />
            <Route path="/aplicativos/:id" element={<AplicativoEdicao />} />
            <Route path="/assuntos" element={<Assunto />} />
            <Route path="/assuntos/:id" element={<AssuntoEdicao />} />
            <Route path="/enviar" element={<NovaNotificacaoTopico />} />
            <Route path="/enviadas" element={<NotificacoesTopico />} />
            <Route path="/enviadas/:id" element={<NotificacaoTopicoEnviada />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/templates/:id" element={<TemplateEdicao />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    );
}

export default MenuRouter;
