import Api, { IPaginationRequest, IPaginationResponse } from '../api/Api';
import { TypeTipoTemplate } from './TemplateSvc';

export interface IObterNotificacaosParam {
    aplicativoId: string;
    assuntoId?: string;
    textoPesquisa?: string;
}

export interface IObterNotificacaosTopico {
    id: string;
    aplicativoId: string;
    titulo: string;
    conteudo: string;
    criadoEm: Date;
    assunto: {
        id: string;
        titulo: string;
    };
}

export interface IObterNotificacaosRequest extends IObterNotificacaosParam, IPaginationRequest {}

export interface IObterNotificacaosResponse extends IPaginationResponse<IObterNotificacaosTopico> {}

export interface IObterNotificacaoPorIdResponse {
    id: string;
    aplicativoId: string;
    assuntoId: string;
    tipo: string;
    titulo: string;
    conteudo: string;
    criadoEm: Date;
    criadoPor: string;
    alteradoEm: Date;
    alteradoPor: string;
    metadado?: string;
    notifRender: string;
    tecRender?: 'yaml-handlebars' | 'html-handlebars';
    aplicativo: {
        id: string;
        nome: string;
    };
    assunto: {
        id: string;
        titulo: string;
    };
    info: {
        total: number;
        lidas: number;
        naoLidas: number;
        lidasPorDia: Array<{
            data: Date;
            total: number;
        }>;
        lidasPorHora: Array<{
            hora: string;
            total: number;
        }>;
    };
}

export interface IEnviarNotificacaoTopicoRequest {
    aplicativo: {
        chaveUnica: string;
    };
    assunto: {
        chaveUnica: string;
    };
    tipo: 'APP' | 'PUSH' | 'APP e PUSH';
    titulo: string;
    conteudo: string;
    metadado?: string;
    html?: string;
    tecRender?: TypeTipoTemplate;
}

export interface IEnviarNotificacaoTopicoResponse {
    id: string;
}

async function enviarNotificacaoTopico(
    params: IEnviarNotificacaoTopicoRequest
): Promise<IEnviarNotificacaoTopicoResponse> {
    const response = await Api.post('/v2/notificacao/topico', params, 300000);
    return response.data;
}

async function obterNotificacoesTopico(
    params: IObterNotificacaosRequest
): Promise<IObterNotificacaosResponse> {
    const response = await Api.get('/v2/notificacao/topico', params);
    return response.data;
}

async function obterNotificacaoTopicoPorId(id: string): Promise<IObterNotificacaoPorIdResponse> {
    const response = await Api.get(`/v2/notificacao/topico/${id}`);
    return response.data;
}

export default {
    enviarNotificacaoTopico,
    obterNotificacoesTopico,
    obterNotificacaoTopicoPorId,
};
