function validaPadraoChaveUnica(texto: string) {
    function caracterValido(caracter: string) {
        const validos = 'abcdefghijklmnopqrstuvwxyz-';
        let valido = false;
        for (let i = 0; i < validos.length; i++) {
            if (caracter === validos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    }

    let valido = false;

    for (let i = 0; i < texto.length; i++) {
        valido = caracterValido(texto[i]);

        if (!valido) {
            break;
        }
    }

    return valido;
}

function validaIdCamelCase(texto: string) {
    function caracterValido(caracter: string) {
        const validos = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let valido = false;
        for (let i = 0; i < validos.length; i++) {
            if (caracter === validos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    }

    let valido = false;

    for (let i = 0; i < texto.length; i++) {
        valido = caracterValido(texto[i]);

        if (!valido) {
            break;
        }
    }

    return valido;
}

export default {
    validaPadraoChaveUnica,
    validaIdCamelCase,
};
