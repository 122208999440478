import Api, { IPaginationRequest, IPaginationResponse } from '../api/Api';
import { IObterAplicativos } from './AplicativoSvc';

export interface IObterTemplatesParam {
    aplicativoId: string;
    textoPesquisa?: string;
    excluido?: boolean;
}

export interface IObterTemplates {
    id: string;
    aplicativoId: string;
    nome: string;
    tecRender: TypeTipoTemplate;
    criadoEm: Date;
    excluidoEm?: Date;
}

export interface IObterTemplatesRequest extends IObterTemplatesParam, IPaginationRequest {}

export interface IObterTemplatesResponse extends IPaginationResponse<IObterTemplates> {}

export interface IObterTemplatePorIdResponse {
    id: string;
    aplicativoId: string;
    nome: string;
    base: string;
    atributos: string;
    valores: string;
    tecRender: TypeTipoTemplate;
    criadoEm: Date;
    excluidoEm?: Date;
    aplicativo: IObterAplicativos;
}

export interface ICriarAlterarTemplateRequest {
    aplicativoId: string;
    nome: string;
    base: string;
    atributos: string;
    valores: string;
    tecRender: TypeTipoTemplate;
}

export interface ICriarTemplateResponse {
    id: string;
}

export type TypeTipoTemplate = 'yaml-handlebars' | 'html-handlebars';

async function criarTemplate(
    params: ICriarAlterarTemplateRequest
): Promise<ICriarTemplateResponse> {
    const response = await Api.post('/v1/template', params);
    return response.data;
}

async function alterarTemplate(
    id: string,
    params: ICriarAlterarTemplateRequest
): Promise<ICriarTemplateResponse> {
    const response = await Api.put(`/v1/template/${id}`, params);
    return response.data;
}

async function obterTemplates(params: IObterTemplatesRequest): Promise<IObterTemplatesResponse> {
    const response = await Api.get('/v1/template', params);
    return response.data;
}

async function obterTemplatePorId(id: string): Promise<IObterTemplatePorIdResponse> {
    const response = await Api.get(`/v1/template/${id}`);
    return response.data;
}

async function ativarTemplate(id: string): Promise<null> {
    const response = await Api.put(`/v1/template/${id}/ativar`);
    return response.data;
}

async function excluirTemplate(id: string): Promise<null> {
    const response = await Api.delete(`/v1/template/${id}`);
    return response.data;
}

export default {
    criarTemplate,
    alterarTemplate,
    obterTemplates,
    obterTemplatePorId,
    ativarTemplate,
    excluirTemplate,
};
