import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
    url: process.env.REACT_APP_KEYCLOAK_URL ?? '',
});

async function validateSession() {
    try {
        const autenticado = await keycloak.init({
            onLoad: 'login-required',
        });

        if (autenticado) {
            if (!hasAccessClient()) {
                throw new Error('Usuário sem permissão de acesso a plataforma');
            }
            startTimerRefreshToken();
        } else {
            await keycloak.login();
        }
    } catch (error) {
        throw new Error(`Keycloak: ${error?.message ?? error?.error}`);
    }
}

function startTimerRefreshToken() {
    setTimeout(async () => {
        try {
            await keycloak.updateToken(-1);
            startTimerRefreshToken();
        } catch (error) {}
    }, 240000);
}

function getInstance() {
    return keycloak;
}

function getToken() {
    return keycloak.tokenParsed;
}

function hasAccessClient() {
    const client = keycloak?.tokenParsed?.resource_access;
    if (client) {
        try {
            const roles = client['api-notificacao']['roles'];
            return Array.isArray(roles);
        } catch (error) {
            return false;
        }
    }
    return false;
}

function hasSuperAdmin() {
    const client = keycloak?.tokenParsed?.resource_access;
    if (client) {
        const roles = client['api-notificacao']['roles'];
        if (Array.isArray(roles)) {
            return roles.some((role) => role === 'admin');
        }
    }
    return false;
}

function hasAdminApp(appFilter?: string) {
    const client = keycloak?.tokenParsed?.resource_access;
    if (client) {
        const roles = client['api-notificacao']['roles'];
        if (Array.isArray(roles)) {
            return roles.some((role) => {
                const [profile, app] = role.split(':');
                if (!appFilter) {
                    return profile === 'admin';
                } else {
                    return profile === 'admin' && app === appFilter;
                }
            });
        }
    } else {
        return false;
    }
}

function hasUsuarioApp(appFilter?: string) {
    const client = keycloak?.tokenParsed?.resource_access;
    if (client) {
        const roles = client['api-notificacao']['roles'];
        if (Array.isArray(roles)) {
            return roles.some((role) => {
                const [profile, app] = role.split(':');
                if (!appFilter) {
                    return profile === 'usuario';
                } else {
                    return profile === 'usuario' && app === appFilter;
                }
            });
        }
    } else {
        return false;
    }
}

export default {
    validateSession,
    getInstance,
    getToken,
    hasAccessClient,
    hasSuperAdmin,
    hasAdminApp,
    hasUsuarioApp,
};
