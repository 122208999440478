import Api, { IPaginationRequest, IPaginationResponse } from '../api/Api';

export interface IObterAplicativosParam {
    textoPesquisa?: string;
    excluido?: boolean;
}

export interface IObterAplicativos {
    id: string;
    nome: string;
    chaveUnica: string;
    excluidoEm?: Date;
}

export interface IObterAplicativosRequest extends IObterAplicativosParam, IPaginationRequest {}

export interface IObterAplicativosResponse extends IPaginationResponse<IObterAplicativos> {}

export interface IObterAplicativoPorIdResponse {
    id: string;
    nome: string;
    chaveUnica: string;
    credencialFirebase: string;
    excluidoEm?: Date;
}

export interface ICriarAlterarAplicativoRequest {
    nome: string;
    chaveUnica: string;
    credencialFirebase?: string;
}

export interface ICriarAplicativoResponse {
    id: string;
}

async function criarAplicativo(
    params: ICriarAlterarAplicativoRequest
): Promise<ICriarAplicativoResponse> {
    const response = await Api.post('/v1/aplicativo', params);
    return response.data;
}

async function alterarAplicativo(
    id: string,
    params: ICriarAlterarAplicativoRequest
): Promise<ICriarAplicativoResponse> {
    const response = await Api.put(`/v1/aplicativo/${id}`, params);
    return response.data;
}

async function obterAplicativos(
    params: IObterAplicativosRequest
): Promise<IObterAplicativosResponse> {
    const response = await Api.get('/v1/aplicativo', params);
    return response.data;
}

async function obterAplicativoPorId(id: string): Promise<IObterAplicativoPorIdResponse> {
    const response = await Api.get(`/v1/aplicativo/${id}`);
    return response.data;
}

async function ativarAplicativo(id: string): Promise<null> {
    const response = await Api.put(`/v1/aplicativo/${id}/ativar`);
    return response.data;
}

async function excluirAplicativo(id: string): Promise<null> {
    const response = await Api.delete(`/v1/aplicativo/${id}`);
    return response.data;
}

async function sincronizarTopicosComTokensAtivosDoAplicativo(
    aplicativoChaveUnica: string
): Promise<null> {
    const response = await Api.post('/v1/assunto/sincronizar-topicos-token-dispositivos', {
        aplicativoChaveUnica,
    });
    return response.data;
}

export default {
    criarAplicativo,
    alterarAplicativo,
    obterAplicativos,
    obterAplicativoPorId,
    ativarAplicativo,
    excluirAplicativo,
    sincronizarTopicosComTokensAtivosDoAplicativo,
};
