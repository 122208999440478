import { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Divider,
    Typography,
    Form,
    Input,
    notification,
    Button,
    Modal,
    Select,
    Spin,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { BiCodeAlt } from 'react-icons/bi';
import Handlebars from 'handlebars/dist/handlebars.min.js';

import EditorPreviewRender from '../../components/editorPreviewRender/EditorPreviewRender';
import ModalCriarSkeletonValores, { ISkeletonValoresTemplate } from './ModalCriarSkeletonValores';
import ModalInformarValores, { TypeValoresTemplate } from './ModalInformarValores';
import PreviewAppIframe from '../../components/previewAppIframe/PreviewAppIframe';
import ModalShowCode from '../../components/modal/ModalShowCode';
import TemplateSvc, {
    IObterTemplatePorIdResponse,
    TypeTipoTemplate,
} from '../../services/TemplateSvc';
import SearchAplicativo, {
    obterUltimoAplicativoSelecionado,
} from '../../components/input/SearchAplicativo';
import { IObterAplicativos } from '../../services/AplicativoSvc';

const { Text } = Typography;
const { Option } = Select;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
        fontSize: 22,
    },
    textTemplateEngine: {
        marginTop: 10,
    },
    btnSalvar: {
        marginTop: 30,
    },
};

interface IFormValues {
    nome: string;
    tipoTemplate: TypeTipoTemplate;
}

const keyRascunho = '@rascunhoTemplate';

function TemplateEdicao() {
    const params = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm<IFormValues>();
    const [loading, setLoading] = useState(true);
    const [openModalSkeleton, setOpenModalSkeleton] = useState(false);
    const [openModalValores, setOpenModalValores] = useState(false);
    const [openPreviewTextRender, setOpenPreviewTextRender] = useState(false);
    const [openRascunho, setOpenRascunho] = useState(false);
    const [skeletonValores, setSkeletonValores] = useState<ISkeletonValoresTemplate[]>([]);
    const [valores, setValores] = useState<TypeValoresTemplate>({});
    const [aplicativo, setAplicativo] = useState<IObterAplicativos | null>(
        params.id === 'novo' ? obterUltimoAplicativoSelecionado() : null
    );
    const [tipoTemplate, setTipoTemplate] = useState<TypeTipoTemplate>('yaml-handlebars');

    const [textEditor, setTextEditor] = useState<string>('');
    const [textRendered, setTextRendered] = useState<string>('');

    const carregarTemplate = useCallback(async () => {
        try {
            form.setFieldsValue({
                tipoTemplate: 'yaml-handlebars',
            });
            if (params.id === 'novo') {
                setTextEditor(yamlDefault);
                setLoading(false);
                if (localStorage.getItem(keyRascunho)) {
                    setOpenRascunho(true);
                }
                return;
            }

            setLoading(true);
            const response = await TemplateSvc.obterTemplatePorId(params.id ?? '');

            setAplicativo(response.aplicativo);
            setTextEditor(response.base);
            setSkeletonValores(JSON.parse(response.atributos));
            setValores(JSON.parse(response.valores));
            setTipoTemplate(response.tecRender);
            form.setFieldsValue({
                nome: response.nome,
                tipoTemplate: response.tecRender,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [form, params.id]);

    const salvarRascunhoAutomatico = useCallback(async () => {
        try {
            if (params.id !== 'novo') {
                return;
            }

            if (textEditor === '' || textEditor === yamlDefault) {
                return;
            }

            let nome = 'Rascunho';

            try {
                nome = await form.getFieldValue('nome');
            } catch (error) {}

            localStorage.setItem(
                keyRascunho,
                JSON.stringify({
                    nome: nome ?? 'Rascunho',
                    base: textEditor,
                    atributos: JSON.stringify(skeletonValores),
                    valores: JSON.stringify(valores),
                    tecRender: tipoTemplate,
                })
            );
        } catch (error) {}
    }, [form, params.id, skeletonValores, tipoTemplate, valores, textEditor]);

    function descartarRascunho() {
        localStorage.removeItem(keyRascunho);
    }

    function restaurarRascunho() {
        const rascunhoString = localStorage.getItem(keyRascunho);
        if (rascunhoString) {
            const rascunho: IObterTemplatePorIdResponse = JSON.parse(rascunhoString);
            setTextEditor(rascunho.base);
            setSkeletonValores(JSON.parse(rascunho.atributos));
            setValores(JSON.parse(rascunho.valores));
            setTipoTemplate(rascunho.tecRender);
            form.setFieldsValue({
                nome: rascunho.nome,
                tipoTemplate: rascunho.tecRender,
            });
        }
        setOpenRascunho(false);
    }

    async function salvarTemplate() {
        try {
            if (!aplicativo?.id) {
                form.validateFields(['aplicativoId']);
                return;
            }

            const { nome } = await form.validateFields();

            if (params.id === 'novo') {
                const response = await TemplateSvc.criarTemplate({
                    aplicativoId: aplicativo.id,
                    nome,
                    base: textEditor,
                    atributos: JSON.stringify(skeletonValores),
                    tecRender: tipoTemplate,
                    valores: JSON.stringify(valores),
                });

                descartarRascunho();

                navigate(`/templates/${response.id}`);
            } else {
                await TemplateSvc.alterarTemplate(params.id ?? '', {
                    aplicativoId: aplicativo.id,
                    nome,
                    base: textEditor,
                    atributos: JSON.stringify(skeletonValores),
                    tecRender: tipoTemplate,
                    valores: JSON.stringify(valores),
                });
            }

            notification.success({ message: 'Template salvo com sucesso!' });
        } catch (error) {
            if (!error.errorFields) {
                notification.error({ message: 'Oops!', description: error.message });
            }
        }
    }

    useEffect(() => {
        try {
            const render = Handlebars.compile(textEditor, {
                noEscape: true,
            });
            const result = render(valores);
            setTextRendered(result);
            salvarRascunhoAutomatico();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }, [salvarRascunhoAutomatico, valores, textEditor]);

    useEffect(() => {
        carregarTemplate();
    }, [carregarTemplate]);

    return (
        <>
            <div className="base-page">
                <div className="base-page-content">
                    <Spin spinning={loading}>
                        <Row>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <Row gutter={[16, 0]} align="middle">
                                            <Col className="center-content no-padding" span={1}>
                                                <BiCodeAlt style={styles.iconPage} size={25} />
                                            </Col>
                                            <Col>
                                                <Text style={styles.textTitle}>Novo template</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={24}>
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 24 }}
                                            autoComplete="off"
                                        >
                                            <Row gutter={[15, 0]}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label="Aplicativo"
                                                                labelCol={{ span: 24 }}
                                                            >
                                                                <SearchAplicativo
                                                                    formItemName="aplicativo"
                                                                    form={form}
                                                                    value={aplicativo}
                                                                    onChange={(val) =>
                                                                        setAplicativo(val)
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Nome do template"
                                                        name="nome"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Informe o nome do template!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input maxLength={30} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Tipo do template"
                                                        name="tipoTemplate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Informe o nome do template!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            value={tipoTemplate}
                                                            onChange={(val) => setTipoTemplate(val)}
                                                        >
                                                            <Option value="yaml-handlebars">
                                                                yaml-handlebars
                                                            </Option>
                                                            <Option value="html-handlebars">
                                                                html-handlebars
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Row>
                                            <Col span={12}>
                                                <Row gutter={[16, 0]}>
                                                    <Col>
                                                        <Button
                                                            type="primary"
                                                            onClick={() =>
                                                                setOpenModalSkeleton(true)
                                                            }
                                                        >
                                                            Skeleton
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type="primary"
                                                            onClick={() =>
                                                                setOpenModalValores(true)
                                                            }
                                                        >
                                                            Informar valores
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={12}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Button
                                                            type="primary"
                                                            onClick={() =>
                                                                setOpenPreviewTextRender(true)
                                                            }
                                                        >
                                                            Preview{' '}
                                                            {tipoTemplate === 'yaml-handlebars'
                                                                ? 'yaml'
                                                                : 'html'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Row>
                                            <Col span={24}>
                                                <EditorPreviewRender
                                                    value={textEditor}
                                                    onChange={(val) => setTextEditor(val)}
                                                    lang={
                                                        tipoTemplate === 'yaml-handlebars'
                                                            ? 'yaml'
                                                            : 'html'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <span style={styles.textTemplateEngine}>
                                                Este template engine usa{' '}
                                                <a
                                                    href="https://handlebarsjs.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {' '}
                                                    Handlebars
                                                </a>
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="start" align="bottom" style={styles.btnSalvar}>
                                    <Button type="primary" onClick={salvarTemplate}>
                                        Salvar
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </div>

            <Col span={7}>
                <PreviewAppIframe textRendered={textRendered} type={tipoTemplate} />
            </Col>

            {openModalSkeleton && (
                <ModalCriarSkeletonValores
                    visible={openModalSkeleton}
                    onCancel={() => setOpenModalSkeleton(false)}
                    skeletonValores={skeletonValores}
                    onOk={(vals: ISkeletonValoresTemplate[]) => setSkeletonValores(vals)}
                />
            )}
            {openModalValores && (
                <ModalInformarValores
                    visible={openModalValores}
                    onCancel={() => setOpenModalValores(false)}
                    skeletonValores={skeletonValores}
                    valores={valores}
                    onOk={(vals: TypeValoresTemplate) => setValores(vals)}
                />
            )}
            {openPreviewTextRender && (
                <ModalShowCode
                    visible={openPreviewTextRender}
                    onCancel={() => setOpenPreviewTextRender(false)}
                    code={textRendered}
                    lang={tipoTemplate === 'yaml-handlebars' ? 'yaml' : 'html'}
                />
            )}
            {openRascunho && (
                <Modal
                    title="Rascunho pendente"
                    visible={openRascunho}
                    onOk={() => restaurarRascunho()}
                    onCancel={() => {
                        descartarRascunho();
                        setOpenRascunho(false);
                    }}
                    okText="Restaurar"
                    cancelText="Descartar"
                >
                    <p>Foi encontrado um rascunho pendente, deseja restaurar ou descartar?</p>
                </Modal>
            )}
        </>
    );
}

const yamlDefault = `version: v1
root:
    type: View
    props:
        style:
            flex: 1
            justifyContent: center
            alignItems: center
    children:
        - type: Text
          props:
            style:
              fontSize: 16
          children: "Novo Template"`;

export default TemplateEdicao;
