import { CSSProperties } from 'react';

const styles: { [key: string]: CSSProperties } = {
    root: {
        maxWidth: 320,
        minWidth: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0px 5px',
    },
    device: {
        width: '100%',
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '10px solid #000',
        margin: '5px 10px',
        paddingBottom: '10px',
        borderRadius: '40px 40px 0px 0px',
        borderBottom: '0px',
        backgroundImage: 'url("/images/ios_wallpaper.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
    },
    popup: {
        background:
            'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 35%, rgba(255,255,255,0.8) 100%)',
        backdropFilter: 'saturate(180%) blur(10px)',
        marginTop: '10px',
        width: '90%',
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
        borderRadius: 15,
        padding: '5px 10px',
    },
    title: {
        fontSize: 13,
        color: 'rgba(0,0,0,0.9)',
        fontWeight: 'bold',
    },
    description: {
        fontSize: 12,
        color: '#000',
    },
    notch: {
        width: '60%',
        borderRadius: '0px 0px 15px 15px',
        height: 20,
        backgroundColor: '#000',
    },
};

export default function PreviewPopupNotif({
    title,
    description,
}: {
    title: string;
    description: string;
}) {
    return (
        <div style={styles.root}>
            <div style={styles.device}>
                <div style={styles.notch} />
                <div style={styles.popup}>
                    <div style={styles.title}>{title}</div>
                    <div style={styles.description}>{description}</div>
                </div>
            </div>
        </div>
    );
}
