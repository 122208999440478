import { CSSProperties } from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import Lottie from 'react-lottie';
import ErrorLottie from '../../assets/lottie/error.json';

const { Title } = Typography;

const styles: { [key: string]: CSSProperties } = {
    root: {
        width: '100%',
        height: '100%',
    },
};

interface ISplashScreenProps {
    error?: string;
}

function SplashScreen({ error }: ISplashScreenProps) {
    return (
        <Row justify="space-around" align="middle" style={styles.root}>
            {error ? (
                <Row align="middle">
                    <Col>
                        <Lottie
                            height={250}
                            width={250}
                            options={{
                                animationData: ErrorLottie,
                                loop: false,
                            }}
                        />
                    </Col>
                    <Col>
                        <Title level={3}>Oops! ocorreu um erro!</Title>
                        <Title level={5}>{error}</Title>
                    </Col>
                </Row>
            ) : (
                <Spin size="large" tip="Carregando..." />
            )}
        </Row>
    );
}

export default SplashScreen;
