import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

// Pages
import TelaInicial from './pages/telaInicial/TelaInicial';

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<TelaInicial />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
