import { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Divider,
    Typography,
    notification,
    Form,
    Input,
    Statistic,
    Progress,
    Card,
    Spin,
} from 'antd';
import { useParams } from 'react-router-dom';
import { IoMdPaperPlane } from 'react-icons/io';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import PreviewAppIframe from '../../components/previewAppIframe/PreviewAppIframe';
import ModalShowCode from '../../components/modal/ModalShowCode';
import NotificacaoTopicoSvc, {
    IObterNotificacaoPorIdResponse,
} from '../../services/NotificacaoTopicoSvc';
import DataFormat from '../../utils/DataFormat';
import PreviewPopupNotif from './PreviewPopupNotif';

const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
        fontSize: 22,
    },
};

interface IBarChartDia {
    data: string;
    total: number;
}

interface IBarChartHora {
    hora: string;
    total: number;
}

function NotificacaoTopicoEnviada() {
    const params = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [openPreviewTextRender, setOpenPreviewTextRender] = useState(false);
    const [notificacao, setNotificacao] = useState<IObterNotificacaoPorIdResponse | null>(null);
    const [grafDia, setGrafDia] = useState<IBarChartDia[]>([]);
    const [grafHora, setGrafHora] = useState<IBarChartHora[]>([]);

    const carregar = useCallback(async () => {
        try {
            setLoading(true);
            const response = await NotificacaoTopicoSvc.obterNotificacaoTopicoPorId(
                params.id ?? ''
            );
            setNotificacao(response);
            form.setFieldsValue({
                aplicativo: response.aplicativo.nome,
                assunto: response.assunto.titulo,
                titulo: response.titulo,
                conteudo: response.conteudo,
                criadoEm: DataFormat.formatarDataHora(response.criadoEm),
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [form, params.id]);

    useEffect(() => {
        if (notificacao) {
            setGrafDia(
                notificacao?.info?.lidasPorDia.map((it) => {
                    const date = new Date(it.data);
                    const dateTimeZone = new Date(
                        date.getUTCFullYear(),
                        date.getUTCMonth(),
                        date.getUTCDate(),
                        0,
                        0,
                        0,
                        0
                    );
                    return {
                        data: DataFormat.formatarDataAbreviada(dateTimeZone),
                        total: it.total,
                    };
                })
            );
            setGrafHora(
                notificacao?.info?.lidasPorHora.map((it) => {
                    const hora = parseInt(it.hora);
                    const agoraInicio = new Date();
                    agoraInicio.setUTCHours(hora);
                    const agoraFim = new Date();
                    agoraFim.setUTCHours(hora);
                    agoraFim.setHours(agoraFim.getHours() + 1);
                    return {
                        hora: `${String(agoraInicio.getHours()).padStart(2, '0')}h-${String(
                            agoraFim.getHours()
                        ).padStart(2, '0')}h`,
                        total: it.total,
                    };
                })
            );
        }
    }, [notificacao]);

    useEffect(() => {
        carregar();
    }, [carregar]);

    return (
        <>
            <div className="base-page">
                <div className="base-page-content">
                    <Spin spinning={loading}>
                        <Row>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <Row gutter={[16, 0]} align="middle">
                                            <Col className="center-content no-padding" span={1}>
                                                <IoMdPaperPlane size={25} />
                                            </Col>
                                            <Col>
                                                <Text style={styles.textTitle}>
                                                    Notificação enviada
                                                </Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={24}>
                                        <Form form={form} labelCol={{ span: 24 }}>
                                            <Row gutter={[15, 20]}>
                                                <Col span={12}>
                                                    <Form.Item label="Aplicativo" name="aplicativo">
                                                        <Input readOnly />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="Assunto" name="assunto">
                                                        <Input readOnly />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Divider />
                                            <Row gutter={[15, 20]}>
                                                <Col span={12}>
                                                    <Form.Item label="Título" name="titulo">
                                                        <Input readOnly />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Row justify="end">
                                                        {notificacao && (
                                                            <PreviewPopupNotif
                                                                title={notificacao?.titulo ?? ''}
                                                                description={
                                                                    notificacao?.conteudo ?? ''
                                                                }
                                                            />
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item label="Descrição" name="conteudo">
                                                        <Input.TextArea readOnly />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Divider />
                                            <Row gutter={[0, 20]}>
                                                <Col span={12}>
                                                    <Form.Item label="Enviado em" name="criadoEm">
                                                        <Input readOnly />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Divider />
                                            {notificacao && (
                                                <>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item label="Notificações lidas">
                                                                <Statistic
                                                                    value={notificacao?.info.lidas}
                                                                    suffix={`/ ${notificacao?.info?.total}`}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label="Progresso">
                                                                <Progress
                                                                    type="circle"
                                                                    percent={calcPerc(
                                                                        notificacao?.info.lidas,
                                                                        notificacao?.info?.total
                                                                    )}
                                                                    width={50}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[0, 15]}>
                                                        <Col span={24}>
                                                            <Card title="Quantidade lida nos 15 primeiros dias">
                                                                <ResponsiveContainer
                                                                    width="100%"
                                                                    aspect={21 / 9}
                                                                >
                                                                    <BarChart data={grafDia}>
                                                                        <XAxis dataKey="data" />
                                                                        <YAxis
                                                                            tickFormatter={(val) =>
                                                                                String(
                                                                                    Math.round(val)
                                                                                )
                                                                            }
                                                                        />
                                                                        <Tooltip />
                                                                        <Bar
                                                                            dataKey="total"
                                                                            fill="#1890ff"
                                                                        />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </Card>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Card title="Quantidade lida por hora do dia">
                                                                <ResponsiveContainer
                                                                    width="100%"
                                                                    aspect={21 / 9}
                                                                >
                                                                    <BarChart data={grafHora}>
                                                                        <XAxis dataKey="hora" />
                                                                        <YAxis
                                                                            tickFormatter={(val) =>
                                                                                String(
                                                                                    Math.round(val)
                                                                                )
                                                                            }
                                                                        />
                                                                        <Tooltip />
                                                                        <Bar
                                                                            dataKey="total"
                                                                            fill="#106121"
                                                                        />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </div>

            <Col span={7}>
                {notificacao && (
                    <PreviewAppIframe
                        textRendered={notificacao?.notifRender ?? ''}
                        type={notificacao?.tecRender ?? 'html-handlebars'}
                        title={notificacao?.titulo}
                    />
                )}
            </Col>

            {openPreviewTextRender && (
                <ModalShowCode
                    visible={openPreviewTextRender}
                    onCancel={() => setOpenPreviewTextRender(false)}
                    code={notificacao?.notifRender ?? ''}
                    lang={notificacao?.tecRender === 'yaml-handlebars' ? 'yaml' : 'html'}
                />
            )}
        </>
    );
}

function calcPerc(value?: number, total?: number) {
    if (value === undefined || total === undefined) {
        return 0;
    }

    if (value === 0 || total === 0) {
        return 0;
    }

    return Math.round((value / total) * 100);
}

export default NotificacaoTopicoEnviada;
