import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import {
    Row,
    Input,
    Col,
    Table,
    Space,
    Divider,
    Button,
    notification,
    Typography,
    Form,
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { IoMdPaperPlane } from 'react-icons/io';

import Keycloak from '../../utils/Keycloak';
import { DEFAULT_PAGE_SIZE } from '../../api/Api';
import NotificacaoTopicoSvc, {
    IObterNotificacaosTopico,
} from '../../services/NotificacaoTopicoSvc';
import SearchAplicativo, {
    obterUltimoAplicativoSelecionado,
} from '../../components/input/SearchAplicativo';
import { IObterAplicativos } from '../../services/AplicativoSvc';
import DataFormat from '../../utils/DataFormat';

const { Search } = Input;
const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconPage: {
        color: 'black',
        fontSize: 28,
    },
    inputSearch: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    iconDelete: {
        color: 'red',
    },
};

function NotificacaoTopicos() {
    const timeSearch = useRef<any>(null);
    const [form] = Form.useForm();
    const [aplicativo, setAplicativo] = useState<IObterAplicativos | null>(
        obterUltimoAplicativoSelecionado()
    );
    const [textoPesquisa, setTextoPesquisa] = useState('');
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [notificacaoTopicos, setNotificacaoTopicos] = useState<IObterNotificacaosTopico[]>([]);

    const columns = [
        {
            title: 'Título',
            dataIndex: 'titulo',
            key: 'titulo',
        },
        {
            title: 'Descrição',
            dataIndex: 'conteudo',
            key: 'conteudo',
        },
        {
            title: 'Assunto',
            dataIndex: 'tituloAssunto',
            key: 'tituloAssunto',
            render: (_: string, item: IObterNotificacaosTopico) => (
                <span>{item.assunto.titulo}</span>
            ),
        },
        {
            title: 'Enviado em',
            dataIndex: 'criadoEm',
            key: 'criadoEm',
            render: (_: string, item: IObterNotificacaosTopico) => (
                <span>{DataFormat.formatarDataHora(item.criadoEm)}</span>
            ),
        },
        {
            title: '',
            key: 'acao',
            align: 'center' as const,
            render: (_: string, item: IObterNotificacaosTopico) => (
                <Space size="middle">
                    <Link to={`/enviadas/${item.id}`}>
                        <Button type="dashed" shape="circle" icon={<EyeOutlined />} />
                    </Link>
                </Space>
            ),
        },
    ];

    const carregar = useCallback(
        async (page: number, pageSize: number) => {
            if (!aplicativo?.id) {
                return;
            }

            try {
                setLoading(true);
                const response = await NotificacaoTopicoSvc.obterNotificacoesTopico({
                    aplicativoId: aplicativo?.id,
                    page,
                    limit: pageSize,
                    textoPesquisa,
                });

                setCurrentPage(response.page);
                setTotal(response.total);
                setNotificacaoTopicos(response.result);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                notification.error({ message: 'Oops!', description: error.message });
            }
        },
        [aplicativo?.id, textoPesquisa]
    );

    useEffect(() => {
        carregar(1, DEFAULT_PAGE_SIZE);
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Row>
                    <Col span={12}>
                        <Row gutter={[16, 0]} align="middle">
                            <Col className="center-content no-padding" span={1}>
                                <IoMdPaperPlane size={25} />
                            </Col>
                            <Col>
                                <Text style={styles.textTitle}>
                                    Notificações por tópico enviadas
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                    {Keycloak.hasSuperAdmin() && (
                        <Col span={12}>
                            <Row align="middle" justify="end">
                                <Link to={`/enviar`}>
                                    <Button type="primary">Nova Notificação</Button>
                                </Link>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Divider />
                <Form form={form}>
                    <Row gutter={[32, 0]} align="middle">
                        <Col span={10}>
                            <Form.Item label="Aplicativo" labelCol={{ span: 24 }}>
                                <SearchAplicativo
                                    filtroExcluidos
                                    value={aplicativo}
                                    formItemName="aplicativo"
                                    form={form}
                                    onChange={(val) => setAplicativo(val)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[32, 0]} align="middle">
                    <Col span={6}>
                        <Search
                            placeholder="Pesquisar notificacaoTopico..."
                            onSearch={(val) => setTextoPesquisa(val)}
                            style={styles.inputSearch}
                            onChange={(e) => {
                                clearTimeout(timeSearch.current);
                                timeSearch.current = setTimeout(() => {
                                    setTextoPesquisa(e.target.value);
                                }, 600);
                            }}
                        />
                    </Col>
                </Row>

                <Divider />
                <Row justify="start">
                    <Col span={24}>
                        <Table
                            style={styles.table}
                            loading={loading}
                            columns={columns}
                            dataSource={notificacaoTopicos}
                            rowKey={({ id }) => String(id)}
                            pagination={{
                                current: currentPage,
                                defaultCurrent: 1,
                                pageSize: DEFAULT_PAGE_SIZE,
                                total: total,
                                onChange: (page, pageSize) =>
                                    carregar(page, pageSize ?? DEFAULT_PAGE_SIZE),
                                showTotal: (registros: number, range: number[]) =>
                                    `${range[0]}-${range[1]} de ${total} registro(s)`,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NotificacaoTopicos;
