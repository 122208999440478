import Api from '../api/Api';

export interface IObterDashboardAplicativoResponse {
    totalUsuarios: number;
    totalDispositivos: number;
    totalNotificacoes: number;
    ultimaNotificacao: {
        id: string;
        titulo: string;
        criadoEm: Date;
    };
}

async function obterDashboardAplicativo(id: string): Promise<IObterDashboardAplicativoResponse> {
    const response = await Api.get(`/v1/dashboard/${id}`);
    return response.data;
}

export default {
    obterDashboardAplicativo,
};
