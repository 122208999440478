import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Row, Col, Divider, notification, Typography, Form, Statistic, Card, Spin } from 'antd';
import { RiDashboardLine } from 'react-icons/ri';
import { IoMdPaperPlane } from 'react-icons/io';
import { BiDevices, BiUser } from 'react-icons/bi';
import { MdOutlineNotificationsActive } from 'react-icons/md';

import SearchAplicativo, {
    obterUltimoAplicativoSelecionado,
} from '../../components/input/SearchAplicativo';
import { IObterAplicativos } from '../../services/AplicativoSvc';
import DashboardSvc, { IObterDashboardAplicativoResponse } from '../../services/DashboardSvc';

const { Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
    textTitle: {
        fontSize: 20,
    },
    iconCard: {
        marginRight: 15,
        width: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

function Dashboard() {
    const [form] = Form.useForm();
    const [aplicativo, setAplicativo] = useState<IObterAplicativos | null>(
        obterUltimoAplicativoSelecionado()
    );
    const [dasboard, setDashboard] = useState<IObterDashboardAplicativoResponse | null>(null);
    const [loading, setLoading] = useState(false);

    const carregar = useCallback(async () => {
        if (!aplicativo?.id) {
            return;
        }

        try {
            setLoading(true);
            const response = await DashboardSvc.obterDashboardAplicativo(aplicativo.id);
            setDashboard(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: 'Oops!', description: error.message });
        }
    }, [aplicativo?.id]);

    useEffect(() => {
        carregar();
    }, [carregar]);

    return (
        <div className="base-page">
            <div className="base-page-content">
                <Spin spinning={loading}>
                    <Row>
                        <Col span={12}>
                            <Row gutter={[16, 0]} align="middle">
                                <Col className="center-content no-padding" span={1}>
                                    <RiDashboardLine size={25} />
                                </Col>
                                <Col>
                                    <Text style={styles.textTitle}>Dashboard</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Form form={form}>
                        <Row gutter={[32, 0]} align="middle">
                            <Col span={10}>
                                <Form.Item label="Aplicativo" labelCol={{ span: 24 }}>
                                    <SearchAplicativo
                                        filtroExcluidos
                                        value={aplicativo}
                                        formItemName="aplicativo"
                                        form={form}
                                        onChange={(val) => setAplicativo(val)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Divider />
                    <Row gutter={[16, 0]}>
                        <Col span={6}>
                            <Card title="Usuários">
                                <Statistic
                                    value={dasboard?.totalUsuarios ?? 0}
                                    prefix={
                                        <div style={styles.iconCard}>
                                            <BiUser size={30} />
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title="Dispositivos">
                                <Statistic
                                    value={dasboard?.totalDispositivos ?? 0}
                                    prefix={
                                        <div style={styles.iconCard}>
                                            <BiDevices size={30} />
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title="Notificações enviadas">
                                <Statistic
                                    value={dasboard?.totalNotificacoes ?? 0}
                                    prefix={
                                        <div style={styles.iconCard}>
                                            <IoMdPaperPlane size={30} />
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                title="Última notificação"
                                extra={
                                    <a href={`/enviadas/${dasboard?.ultimaNotificacao?.id}`}>Ver</a>
                                }
                            >
                                <Statistic
                                    value={dasboard?.ultimaNotificacao?.titulo}
                                    prefix={
                                        <div style={styles.iconCard}>
                                            <MdOutlineNotificationsActive size={30} />
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div>
    );
}

export default Dashboard;
