import { notification } from 'antd';
import Api, { DEFAULT_PAGE_SIZE, IPaginationRequest, IPaginationResponse } from '../api/Api';

export interface IObterAssuntos {
    id: string;
    titulo: string;
    descricao: string;
    identificadorImagem: string;
    chaveUnica: string;
    chaveAcaoAPP: string;
    opcional: boolean;
    topico: boolean;
    excluidoEm?: Date;
    ativo: boolean;
}

export interface IObterAssuntosParam {
    aplicativoId?: string;
    titulo?: string;
    topico?: boolean;
    opcional?: boolean;
    assuntoExcluido?: boolean;
    chaveAcaoAPP?: string;
}

export interface IObterAssuntoPorIdResponse {
    id: string;
    titulo: string;
    descricao: string;
    identificadorImagem: string;
    chaveUnica: string;
    chaveAcaoAPP: string;
    opcional: boolean;
    topico: boolean;
    excluidoEm?: Date;
    aplicativoId: string;
}

export interface ICriarAlterarAssuntoRequest {
    aplicativoId: string;
    titulo: string;
    descricao: string;
    identificadorImagem: string;
    chaveUnica: string;
    chaveAcaoAPP: string;
    opcional: boolean;
    topico: boolean;
    excluidoEm?: Date;
}

export interface ICriarAssuntoResponse {
    id: string;
}

export interface IObterAssuntosRequest extends IObterAssuntosParam, IPaginationRequest {}

export interface IObterAssuntosResponse extends IPaginationResponse<IObterAssuntos> {}

async function obterAssuntos2(params: IObterAssuntosRequest): Promise<IObterAssuntosResponse> {
    const response = await Api.get('v1/assunto/aplicativo-assunto', params);

    let assuntos: IObterAssuntos[] = [];
    for (const element of response.data.result) {
        let assunto = element.assunto;
        assunto.ativo = !element.assunto.excluidoEm ? true : false;

        assuntos.push(assunto);
    }

    response.data.result = assuntos;

    return response.data;
}

async function obterAssuntos(params: IObterAssuntosRequest): Promise<IObterAssuntosResponse> {
    if (!params.aplicativoId) {
        if (params.titulo !== '' || params.topico || params.opcional || params.assuntoExcluido) {
            notification.info({ message: 'Selecione um aplicativo.' });
        }

        const response: IObterAssuntosResponse = {
            result: [],
            limit: params.limit ? 1 : DEFAULT_PAGE_SIZE,
            total: 0,
            page: 1,
        };

        return response;
    }

    const response = await Api.get('v1/assunto/aplicativo-assunto', params);

    let assuntos: IObterAssuntos[] = [];
    for (const element of response.data.result) {
        let assunto = element.assunto;
        assunto.ativo = !element.assunto.excluidoEm ? true : false;

        assuntos.push(assunto);
    }

    response.data.result = assuntos;

    return response.data;
}

async function excluirAssunto(id: string): Promise<null> {
    const response = await Api.delete(`/v1/assunto/${id}`);
    return response.data;
}

async function ativarAssunto(id: string): Promise<null> {
    const response = await Api.put(`/v1/assunto/${id}/ativar`);
    return response.data;
}

async function obterAssuntoPorId(id: string): Promise<IObterAssuntoPorIdResponse> {
    const response = await Api.get(`/v1/assunto/${id}`);
    return response.data;
}

export default {
    obterAssuntos2,
    obterAssuntos,
    excluirAssunto,
    ativarAssunto,
    obterAssuntoPorId,
    criarAssunto,
    alterarAssunto,
};

async function criarAssunto(params: ICriarAlterarAssuntoRequest): Promise<ICriarAssuntoResponse> {
    const response = await Api.post('/v1/assunto', params);
    return response.data;
}

async function alterarAssunto(
    id: string,
    params: ICriarAlterarAssuntoRequest
): Promise<ICriarAssuntoResponse> {
    const response = await Api.put(`/v1/assunto/${id}`, params);
    return response.data;
}
