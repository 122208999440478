import { useEffect } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Space, Table } from 'antd';
import { useState } from 'react';
import {
    DeleteOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    EditOutlined,
} from '@ant-design/icons';
import FieldValidator from '../../utils/FieldValidator';

const { Option } = Select;

export interface ISkeletonValoresTemplate {
    id: string;
    label: string;
    type: 'text' | 'grid';
    value?: string;
    grid?: IValoresGridTemplate[];
}

export interface IValoresGridTemplate {
    id: string;
    label: string;
    value?: string;
    type: 'text';
}

interface IModalCriarValoresProps {
    visible: boolean;
    onCancel: () => void;
    skeletonValores: ISkeletonValoresTemplate[];
    onOk: (valor: ISkeletonValoresTemplate[]) => void;
}

export default function ModalCriarSkeletonValores({
    visible,
    onCancel,
    onOk,
    skeletonValores,
}: IModalCriarValoresProps) {
    const [skeletonValoresEdit, setSkeletonValoresEdit] =
        useState<ISkeletonValoresTemplate[]>(skeletonValores);
    const [openModalCriar, setOpenModalCriar] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState<ISkeletonValoresTemplate | null>(null);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '',
            key: '',
            dataIndex: '',
            render: (_: string, item: ISkeletonValoresTemplate, index: number) => (
                <Row justify="center">
                    <Space size="middle">
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<ArrowUpOutlined />}
                            onClick={() => setOrderUp(index)}
                        />
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<ArrowDownOutlined />}
                            onClick={() => setOrderDown(index)}
                        />
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={() => editItem(item)}
                        />
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={() => remItemGrid(item.id)}
                        />
                    </Space>
                </Row>
            ),
        },
    ];

    function remItemGrid(id: string) {
        setSkeletonValoresEdit((val) => {
            let newVal = [...val];
            return newVal.filter((e) => e.id !== id);
        });
    }

    function arrayMoveIndex(arr: ISkeletonValoresTemplate[], fromIndex: number, toIndex: number) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    }

    function setOrderUp(index: number) {
        if (index === 0) {
            return;
        }
        setSkeletonValoresEdit((val) => {
            let newVal = [...val];
            return arrayMoveIndex(newVal, index, index - 1);
        });
    }

    function setOrderDown(index: number) {
        if (index === skeletonValoresEdit.length - 1) {
            return;
        }
        setSkeletonValoresEdit((val) => {
            let newVal = [...val];
            return arrayMoveIndex(newVal, index, index + 1);
        });
    }

    function editItem(skeleton: ISkeletonValoresTemplate) {
        setItemSelecionado(skeleton);
        setOpenModalCriar(true);
    }

    function addOrEditSkeleton(
        skeleton: ISkeletonValoresTemplate,
        oldSkeleton: ISkeletonValoresTemplate | null
    ) {
        if (oldSkeleton) {
            const skeletonsTemp = [...skeletonValoresEdit];
            const newList = skeletonsTemp.map((it) => (oldSkeleton.id === it.id ? skeleton : it));
            setSkeletonValoresEdit(newList);
        } else {
            const existe = skeletonValoresEdit.some((it) => it.id === skeleton.id);
            if (existe) {
                return notification.error({
                    message: 'Oops!',
                    description: 'ID já existe',
                });
            }
            setSkeletonValoresEdit((val) => {
                let newVal = [...val];
                newVal.push(skeleton);
                return newVal;
            });
        }

        setOpenModalCriar(false);
    }

    function retornarSkeletonValores() {
        onOk(skeletonValoresEdit);
        onCancel();
    }

    return (
        <>
            <Modal
                title="Skeletons"
                visible={visible}
                onOk={retornarSkeletonValores}
                onCancel={onCancel}
                width="50%"
            >
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setItemSelecionado(null);
                                        setOpenModalCriar(true);
                                    }}
                                >
                                    Novo skeleton
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            dataSource={skeletonValoresEdit}
                            pagination={false}
                            rowKey={({ id }) => String(id)}
                        />
                    </Col>
                </Row>
            </Modal>
            {openModalCriar && (
                <ModalCriarValor
                    visible={openModalCriar}
                    onCancel={() => setOpenModalCriar(false)}
                    onOk={addOrEditSkeleton}
                    skeleton={itemSelecionado}
                />
            )}
        </>
    );
}

interface IModalCriarValorProps {
    visible: boolean;
    onCancel: () => void;
    onOk: (edit: ISkeletonValoresTemplate, oldSkeleton: ISkeletonValoresTemplate | null) => void;
    skeleton: ISkeletonValoresTemplate | null;
}

function ModalCriarValor({ visible, onCancel, onOk, skeleton }: IModalCriarValorProps) {
    const [form] = Form.useForm<ISkeletonValoresTemplate>();
    const [formGrid] = Form.useForm<any>();
    const [typeValue, setTypeValue] = useState<'text' | 'grid' | null>(skeleton?.type ?? null);
    const [itensGrid, setItensGrid] = useState<IValoresGridTemplate[]>(skeleton?.grid ?? []);

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '',
            key: '',
            dataIndex: '',
            render: (_: string, item: IValoresGridTemplate, index: number) => (
                <Row justify="center">
                    <Space size="middle">
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<ArrowUpOutlined />}
                            onClick={() => setOrderUp(index)}
                        />
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<ArrowDownOutlined />}
                            onClick={() => setOrderDown(index)}
                        />
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={() => remItemGrid(item.id)}
                        />
                    </Space>
                </Row>
            ),
        },
    ];

    function addItemGrid({ id, label }: IValoresGridTemplate) {
        const existe = itensGrid.some((it) => it.id === id);
        if (existe) {
            return notification.error({
                message: 'Oops!',
                description: 'ID já existe',
            });
        }
        setItensGrid((val) => {
            let newVal = [...val];
            newVal.push({ id, label, type: 'text' });
            return newVal;
        });
        formGrid.resetFields();
    }

    function remItemGrid(id: string) {
        setItensGrid((val) => {
            let newVal = [...val];
            return newVal.filter((e) => e.id !== id);
        });
    }

    function arrayMoveIndex(arr: IValoresGridTemplate[], fromIndex: number, toIndex: number) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    }

    function setOrderUp(index: number) {
        if (index === 0) {
            return;
        }
        setItensGrid((val) => {
            let newVal = [...val];
            return arrayMoveIndex(newVal, index, index - 1);
        });
    }

    function setOrderDown(index: number) {
        if (index === itensGrid.length - 1) {
            return;
        }
        setItensGrid((val) => {
            let newVal = [...val];
            return arrayMoveIndex(newVal, index, index + 1);
        });
    }

    async function salvarValor() {
        try {
            const { id, label, type } = await form.validateFields();
            if (type === 'grid' && itensGrid.length === 0) {
                return notification.error({
                    message: 'Oops!',
                    description: 'Informe pelo menos uma coluna para grid',
                });
            }
            onOk(
                {
                    id,
                    label,
                    type,
                    grid: itensGrid,
                },
                skeleton
            );
        } catch (error) {}
    }

    useEffect(() => {
        if (skeleton) {
            form.setFieldsValue(skeleton);
        }
    }, [form, skeleton]);

    return (
        <Modal
            title={skeleton ? 'Editar skeleton' : 'Novo skeleton'}
            visible={visible}
            onOk={salvarValor}
            onCancel={onCancel}
            width="30%"
        >
            <Form form={form} name="basic" labelCol={{ span: 24 }} autoComplete="off">
                <Form.Item
                    label="id"
                    name="id"
                    rules={[
                        {
                            required: true,
                            message: 'Informe o id do valor',
                        },
                        {
                            message: 'id inválido',
                            validator: async (_, value) => {
                                const val = String(value);
                                if (!FieldValidator.validaIdCamelCase(val)) {
                                    throw new Error('Erro');
                                }
                            },
                        },
                    ]}
                >
                    <Input maxLength={30} placeholder="Ex: nomeAplicativo" />
                </Form.Item>
                <Form.Item
                    label="label"
                    name="label"
                    rules={[
                        {
                            required: true,
                            message: 'Informe a label do valor',
                        },
                    ]}
                >
                    <Input maxLength={30} placeholder="Ex: Nome do aplicativo" />
                </Form.Item>
                <Form.Item
                    label="type"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: 'Informe o type do valor',
                        },
                    ]}
                >
                    <Select onChange={(val) => setTypeValue(val)}>
                        <Option value="text">text</Option>
                        <Option value="grid">grid</Option>
                    </Select>
                </Form.Item>
            </Form>
            {typeValue === 'grid' && (
                <Row>
                    <Col span={24}>
                        <Row>
                            <span>Grid - Informe as colunas</span>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Form
                            form={formGrid}
                            onFinish={(val: any) => addItemGrid(val)}
                            autoComplete="off"
                        >
                            <Row gutter={[15, 0]}>
                                <Col span={10}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="id"
                                        name="id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Informe o id do valor',
                                            },
                                            {
                                                message: 'id inválido',
                                                validator: async (_, value) => {
                                                    const val = String(value);
                                                    if (!FieldValidator.validaIdCamelCase(val)) {
                                                        throw new Error('Erro');
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <Input maxLength={30} placeholder="Ex: nomeAplicativo" />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="label"
                                        name="label"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Informe a label do valor',
                                            },
                                        ]}
                                    >
                                        <Input
                                            maxLength={30}
                                            placeholder="Ex: Nome do aplicativo"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item labelCol={{ span: 24 }} label={<span />}>
                                        <Button type="primary" htmlType="submit">
                                            Add
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={itensGrid}
                                pagination={false}
                                rowKey={({ id }) => String(id)}
                            />
                        </Col>
                    </Col>
                </Row>
            )}
        </Modal>
    );
}
